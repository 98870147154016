import React from 'react'

import { Button, Spinner } from 'reactstrap'
import { StepForm } from '@digitalworkflow/dwtranslateclient'

import './index.scss'
import { LocalSettings } from '../../../utils/LocalSettings'

export type FormButtonCallback = (targetRoute: string) => Promise<void>

export type FormButtonProps = {
  field: StepForm.FieldInfo
  isDisabled: boolean
  isSaving: boolean
  handleSave: FormButtonCallback
  handleNoSaveButton: FormButtonCallback
}

/** Renders a button component found within Field command where options has Button */
const FormFieldButton = ({
  field,
  isSaving,
  handleSave,
  handleNoSaveButton,
  isDisabled
}: FormButtonProps): JSX.Element => {
  const theme = LocalSettings.getTheme() ?? 'bgis'

  /**
  The NoSave option tells the form that we have a button in the form but
  we don't want to save/submit the form.  Just to the page.  This is just like a Button
  except that Button can't be put in a Begin/End Form.

  By default, NoSave has the red/Danger color.  TODO:  Use the style option to find other
  colors and spacing options.
  */

  let classNames = 'col-md-12 mt-3 mb-2'
  if (field.type.checkOption('Float')) classNames = 'me-3 mt-3 mb-2 floatButton float-start'

  if (field.enabled !== undefined && field.enabled === false) classNames += ' disabled'

  if (field.type.checkOption('NoSave')) {
    return (
      <div className={classNames}>
        <Button
          className='save-btn'
          type='button'
          color='danger'
          disabled={isSaving || field.enabled === false}
          onClick={() => handleNoSaveButton(field.option1)}
        >
          {field.text}
          {isSaving && <Spinner className='spinner' />}
        </Button>
      </div>
    )
  }

  return (
    <div className={classNames}>
      <Button
        className='save-btn'
        type='button'
        color={theme === 'io' ? 'edit' : 'primary'}
        disabled={isSaving || isDisabled || field.enabled === false}
        onClick={() => handleSave(field.option1)}
      >
        {field.text}
        {isSaving && <Spinner className='spinner' />}
      </Button>
    </div>
  )
}

export default FormFieldButton
