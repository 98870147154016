import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { LocalSettings } from '../utils/LocalSettings'

/**
 * IdleWatch component.
 *
 * @remarks
 * Log the user out if token does not exist
 *
 * @component IdleWatch
 * @category Component
 */
const IdleWatch = () => {
  const { logout } = useAuth()
  const location = useLocation()

  const checkAuth = (code: number) => {
    const token = LocalSettings.getPortalAuthToken()
    console.log(code, 'checkAuth', token)
    if (!token) {
      console.log('Auth Token is missing, going to logout ')
      logout()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      checkAuth(1)
    }, 150)
  }, [location.pathname])

  useEffect(() => {
    let lastCheck = new Date()

    const eventListener = () => {
      if (new Date().getTime() - lastCheck.getTime() > 1000 * 60 * 5) {
        lastCheck = new Date()
        checkAuth(2)
      }
    }

    document.addEventListener('click', eventListener)
    document.addEventListener('keydown', eventListener)

    return () => {
      document.removeEventListener('click', eventListener)
      document.removeEventListener('keydown', eventListener)
    }
  }, [])

  return <React.Fragment />
}

export default IdleWatch
