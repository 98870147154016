import React from 'react'
import './index.scss'

interface IWorkFlowStateCard {
  state: any
  currentState: number
  totalState: number
}
const WorkFlowStateCard = ({ state, currentState, totalState }: IWorkFlowStateCard) => {
  return (
    <>
      <div className='workflow-container'>
        <div className={'workflow-state ' + (state.selected ? 'selected' : '')}>
          <div className='workflow-state-step'>
            <p className='workflow-text'>{state.text}</p>
            {state.selected ? (
              <i className='fa-regular fa-circle workflow-icon' />
            ) : (
              <span className='workflow-icon'>-</span>
            )}
          </div>
        </div>
      </div>
      {currentState !== totalState && <i className='fa-regular fa-chevron-right workflow-icon'></i>}
    </>
  )
}
export default WorkFlowStateCard
