import React from 'react'

import { FormFieldInputProps, useForm } from '../useForm'
import { LookupType } from '@digitalworkflow/dwtranslateclient'

import '../LookupInput/index.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * BooleanInput component
 *
 * @component BooleanInput
 * @category FormElements
 */
const BooleanInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, setValue, isEditFieldOnModal, isFieldDisabled, renderValidationError } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  // const className = getClassName()

  /**
   * Used to change the value of a radio field.
   *
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
    }
  }

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  const getLookupItemText = (item: LookupType) => {
    return item?.text_en
  }

  if (step.tableDef) {
    const checkboxList: LookupType[] | undefined = step.tableDef?.getLookupTable('YesNoBoolean')

    if (checkboxList.length === 0) {
      checkboxList.push({
        code: '0',
        english: 'No',
        text_en: 'No',
        custom_code: '0',
        sort: 0,
        meta: {}
      })
      checkboxList.push({
        code: '1',
        english: 'Yes',
        text_en: 'Yes',
        custom_code: '1',
        sort: 1,
        meta: {}
      })
    }

    // console.log('YesNoBoolean checkboxList=', checkboxList)

    /** Represents a text formatted version of the field value */
    // const myValueText = step.getValueFormatted(field.field)
    // const myValueDatabase = step.getValueDatabase(field.field)

    const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

    return (
      <div className={isFieldDisabled() ? 'disabled' : ''}>
        {checkboxList
          ? checkboxList.map((item: LookupType) => (
              <div className='form-check boolean-checkbox  mb-1 mr-3' key={item.code}>
                <input
                  type='radio'
                  id={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                  className='form-check-input radio-input'
                  name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
                  value={item.code}
                  checked={fieldValue === item.code}
                  disabled={isFieldDisabled()}
                  onChange={handleChangeRadio}
                  data-testid={field.testid + '_' + item.code}
                />
                <label
                  className={`${isFieldDisabled() ? 'form-check-label disabled' : 'form-check-label'} ${
                    fieldValue === item.code ? 'selected-label' : ''
                  }`}
                  htmlFor={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                >
                  {htmlToReactParser.parse(getLookupItemText(item))}
                </label>
              </div>
            ))
          : ''}
        {renderValidationError()}
      </div>
    )
  }

  return <></>
}

export default BooleanInput
