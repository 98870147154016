import React, { useState } from 'react'
import { Form, InputGroup, Modal } from 'reactstrap'

import { StepForm } from '@digitalworkflow/dwtranslateclient'

/**
 * Props for [[`QueryCreateModal`]] component
 */
interface ModalProps {
  isShowQueryModal: boolean | undefined
  handleQuerySave: (reason: string) => void
  toggleQueryModal: () => void
  queryItem: StepForm.FieldInfo | undefined
}

/**
 * QueryCreateModal component
 *
 * @component QueryCreateModal
 * @category FormElements
 */
const QueryCreateModal = ({ isShowQueryModal, handleQuerySave, toggleQueryModal, queryItem }: ModalProps) => {
  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState('')

  const handleCloseEditModal = () => {
    setMessage('')
    setError('')
    toggleQueryModal()
  }

  const handleSave = () => {
    if (message === '') {
      setError('This field is required and may not be left empty.')
    } else {
      handleQuerySave(message.trim())
      setMessage('')
      setError('')
    }
  }
  return (
    <Modal size='lg' isOpen={isShowQueryModal}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Ask a Question</h5>
        <button type='button' onClick={handleCloseEditModal} className='close' data-dismiss='modal' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        {queryItem && (
          <div id='query-form'>
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                e.preventDefault()
              }}
            >
              <div className='col-md-12'>
                <label className='col-md-12 col-form-label'>Question you would like to ask:</label>
                <div className='col-md-12'>
                  <InputGroup>
                    <input
                      className='form-control'
                      name='reason'
                      value={message}
                      type='text'
                      onChange={(e: any): void => {
                        setMessage(e.target.value)
                      }}
                    />
                  </InputGroup>
                </div>
                <div className='col-md-12'>{error && <span className='invalid-feedback'>{error}</span>}</div>
              </div>
            </Form>
          </div>
        )}
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-primary waves-effect waves-light' onClick={handleSave}>
          Send
        </button>
      </div>
    </Modal>
  )
}

export default QueryCreateModal
