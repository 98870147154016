import React, { useEffect, useRef } from 'react'

import 'react-json-view-lite/dist/index.css'
import './index.scss'
import moment from 'moment'

import { FormTraceItem } from '../../context/DataContext'

interface IFormTraceTabProps {
  traceItems: FormTraceItem[]
  modalRef: React.RefObject<HTMLDivElement>
}

const FormTraceTab = ({ traceItems, modalRef }: IFormTraceTabProps) => {
  const tabRef = useRef<HTMLDivElement>(null)
  traceItems.forEach((item: FormTraceItem, index: number) => {
    if (item.event === 'Trace') {
      let i = index
      while (i > 0) {
        if (traceItems[i].event === 'start') {
          break
        }
        i--
      }
      traceItems[i].trace = item.trace

      const endItem = traceItems.find((f) => f.event === 'end' && f.step.rownum === traceItems[i].step.rownum)
      if (endItem) endItem.trace = item.trace
    }
  })

  console.log(traceItems)

  useEffect(() => {
    modalRef.current?.scrollTo({ top: modalRef.current.scrollHeight, behavior: 'smooth' })
  }, [])

  return (
    <div ref={tabRef}>
      <div className='d-flex justify-content-end'>Step Traces for {traceItems.length} items</div>
      <ul className='verti-timeline list-unstyled'>
        {/* Render Horizontal Timeline Events */}
        {traceItems.map((row: FormTraceItem, rowIndex: number) => (
          <li key={`item-${rowIndex}`} className='event-list'>
            <div className='event-timeline-dot'>
              <i className='fa fa-regular fa-circle-arrow-right'></i>
            </div>
            <div className='d-flex'>
              <div className='me-3'>
                <i className='fa fas fa-atom h2 text-primary' />
              </div>
              {row.event === 'Trace' ? (
                <div className='flex-grow-1'>
                  {' '}
                  <div>
                    <h5>Trace</h5>
                    <div className='d-flex flex-column'>
                      <div className='param flex-grow-1'>Function: {row.trace.functionName}</div>
                      <div className='param flex-grow-1'>Message: {row.trace.message}</div>
                      <div className='param flex-grow-1'>
                        Params:{' '}
                        {row.trace.params
                          .map((param) => {
                            // console.log(row, rowIndex, row.trace.result.value instanceof Date)
                            return param?.value ?? ''
                          })
                          .join(', ')}
                      </div>
                      <div className='param flex-grow-1'>
                        Result:{' '}
                        {row.trace.result.value instanceof Date
                          ? moment(row.trace.result.value).format('YYYY-MM-DD')
                          : row.trace.result.value?.toString()}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex-grow-1'>
                  <div>
                    <h5>
                      {row.step.step_type} {row.event}ed
                    </h5>
                    {row.event === 'start' && row.trace && (
                      <div className='d-flex'>
                        <div className='param flex-grow-1'>Function Name: {row.trace.functionName}</div>
                        <div className='param flex-grow-1'>
                          Params:{' '}
                          {row.trace.params
                            .map((param) => {
                              // console.log(row, rowIndex, row.trace.result.value instanceof Date)
                              return param?.value ?? ''
                            })
                            .join(', ')}
                        </div>
                      </div>
                    )}
                    {row.event === 'end' && row.trace && (
                      <div className='d-flex'>
                        <div className='param flex-grow-1'>Message: {row.trace.message}</div>
                        <div className='param flex-grow-1'>
                          Result:
                          {row.trace.result.value instanceof Date
                            ? moment(row.trace.result.value).format('YYYY-MM-DD')
                            : row.trace.result.value?.toString()}
                        </div>
                      </div>
                    )}
                    <p className='text-muted'>
                      {row.step.rownum} {row.step.text_en} {row.step.step_option} {row.step.field_id}{' '}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FormTraceTab
