import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { LocalSettings } from '../../utils/LocalSettings'

const ThemeLoader = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const urlSegments = location.pathname.split('/io')
    const path = urlSegments[1] ?? '/page/dashboard'
    console.log('Navigating to ', path, ' with IO theme set')
    const theme = 'io'
    LocalSettings.setTheme(theme)
    document.body.classList.add('theme-' + theme ?? 'bgis')
    return navigate(path)
  }, [])

  return <></>
}

export default ThemeLoader
