import React from 'react'

import { FormFieldInputProps, useForm } from '../useForm'
import { DataTools, LookupType } from '@digitalworkflow/dwtranslateclient'

import './lookupimage.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * LookupImage component
 *
 * @component LookupImage
 * @category FormElements
 */
const LookupImage = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, isFieldDisabled, setValue, getClassName, isEditFieldOnModal, renderValidationError } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const className = getClassName()
  console.log('LookupImage myClassName=', className)

  /**
   * Used to change the value of a radio field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
    }
  }

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  const getLookupItemText = (item: LookupType) => {
    return item?.text_en
  }

  if (step.tableDef) {
    /** Represents a lookup table */
    const list = step.tableDef?.getLookupTable(field.field)

    const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

    return (
      <div className={isFieldDisabled() ? 'disabled' : ''}>
        <div className='d-flex flex-row p-2'>
          {list
            ? list.map((item: LookupType) => {
                return (
                  <div className='form-image mb-1' key={item.code}>
                    <label htmlFor={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}>
                      <img src={DataTools.internalValidateString(item.meta.image)} />
                    </label>
                    <br />
                    <input
                      type='radio'
                      id={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                      className='form-check-input radio-input'
                      name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
                      value={item.code}
                      checked={fieldValue === item.code}
                      disabled={isFieldDisabled()}
                      onChange={handleChangeRadio}
                    />
                    <label
                      className={`${isFieldDisabled() ? 'form-check-label disabled' : 'form-check-label'} ${
                        fieldValue === item.code ? 'selected-label' : ''
                      }`}
                      htmlFor={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                    >
                      {htmlToReactParser.parse(getLookupItemText(item))}
                    </label>
                  </div>
                )
              })
            : ''}
        </div>
        {renderValidationError()}
      </div>
    )
  }

  return <></>
}

export default LookupImage
