import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { getEnv } from './constants'

const browserHistory = createBrowserHistory({})
const reactPluginAppInsights = new ReactPlugin()

// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */

const getInsignsKey = () => {
  if (document.location && document.location.hostname === 'localhost') return ''

  if (getEnv() === 'prod')
    return 'InstrumentationKey=1fe86f9b-be4f-43d1-a7d6-621ee8b8614a;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/'

  if (getEnv() === 'staging')
    return 'InstrumentationKey=ade8941b-9325-41ca-afa7-97cece7d8843;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/'

  return ''
}

const myInsightsKey = getInsignsKey()

const appInsights = new ApplicationInsights({
  config: {
    connectionString: myInsightsKey,
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPluginAppInsights],
    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPluginAppInsights.identifier]: { history: browserHistory }
      // *** Add the Click Analytics plug-in. ***
      // [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
})

if (myInsightsKey !== '') appInsights.loadAppInsights()
export { reactPluginAppInsights, appInsights }
