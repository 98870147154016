import React, { useEffect, useState } from 'react'
import { LookupManager } from '@digitalworkflow/dwreactcommon'
import { syncDatabase } from '../../utils/syncDatabase'
import SyncModal from '../../components/SyncModal'

const index = () => {
  const [syncing, setSyncing] = useState(true)

  const [width, setWidth] = useState(0)
  useEffect(() => {
    syncDatabase(setWidth, setSyncing)
  }, [])
  return (
    <div className='p-3'>
      <SyncModal width={width} isOpen={syncing} />
      {!syncing && (
        <LookupManager
          allowEdit
          allowDelete
          metaData={[
            { key: 'psif_orm', title: 'PSIF ORM' },
            { key: 'psif_io_groups_min', title: 'IO Group' },
            { key: 'psif_move_managers', title: 'Move Manager' }
          ]}
        />
      )}
    </div>
  )
}

export default index
