import React, { useEffect, useState, useRef } from 'react'
import { FindTaskResults, StepTaskList, LogicEngine } from '@digitalworkflow/dwtranslateclient'
import { RenderHelper } from '../../RenderHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './TaskList.scss'
import TaskListTable from './TaskListTable'
import TaskListCards from './TaskListCards'
import { LocalSettings } from '../../../utils/LocalSettings'
import { getTaskListSettings } from './TaskListSettings'
import { GlobalHashHelper } from '../../../utils/HashHelper'
import TaskListDebug from './TaskListDebug'
import TaskHistory from './TaskHistory'
import TaskReassign from './TaskReassign'
import { TaskListButtonContextMenu } from './TaskListButtonContextMenu'
import { Tooltip, PlacesType } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

export interface TaskListProps {
  /** Step data */
  step: StepTaskList

  /** Used for redirecting. */
  renderer: RenderHelper

  logic: LogicEngine | undefined
}

/**

Display a list of tasks for the user to complete

*/

type displayModeType = 'list' | 'table'

const TaskList = ({ step, logic }: TaskListProps) => {
  const [allTask, setAllTask] = useState<FindTaskResults[]>([]) // all tasks returned from translate client
  const [taskList, setTaskList] = useState<FindTaskResults[]>([]) // tasks we are showing on main table
  const [subtaskList, setSubTaskList] = useState<FindTaskResults[]>([]) // sub tasks we are showing on slide window
  const [displayMode, setDisplayMode] = useState<displayModeType>(LocalSettings.taskDisplayMode as displayModeType)
  // const [collapsedCategories, setCollapsedCategories] = useState<displayModeType>(
  //   LocalSettings.taskDisplayMode as displayModeType
  // )
  const [showHistoryForTask, setShowHistoryForTask] = useState<FindTaskResults | undefined>(undefined)
  const [reassignTask, setReassignTask] = useState<FindTaskResults | undefined>(undefined)
  const [contextTask, setContextTask] = useState<FindTaskResults | undefined>(undefined)

  const [menuShowing, setMenuShowing] = React.useState<boolean>(false)
  const [menuPosition, setMenuPosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 })

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [subShowingTask, setSubShowingTask] = useState<FindTaskResults | undefined>(undefined)

  useEffect(() => {
    const handleClick = () => setContextTask(undefined)

    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  /** Show tasks that are filtered out because of rejected status */
  const [optionShowRejected, setOptionShowRejected] = useState<boolean>(false)

  /** We need to use a reference to the task list instead of the state only because
  the state update messages are async and the subscribe event can happen too fast
  for a redraw to complete. */
  const myTaskList = useRef<FindTaskResults[]>([])

  /** Display the popup menu upon clicking the share button */
  const handleMoreOptionsMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setMenuShowing(!menuShowing)
    const maxX = window.innerWidth - 400 // Adjust the value as needed
    const maxY = window.innerHeight - 400 // Adjust the value as needed
    const x = Math.min(e.clientX, maxX)
    const y = Math.min(e.clientY, maxY)
    setMenuPosition({ x, y })
  }

  useEffect(() => {
    // Set event to update the task list
    step.subscribe((list) => {
      console.log('TaskList step.subscribe Set Task list:', list)
      const finalList = list
      // const finalList = list.filter((item: FindTaskResults) => {
      //   // if (item && item.task_status === 'Created') {
      //   //   let diff = new Date().getTime() - new Date(item.create_dt.value).getTime()
      //   //   diff /= 86400000
      //   //   if (diff > 2) return false
      //   // }
      //   return true
      // })

      setAllTask(finalList)
      // get tasks with no parents
      let parentLevelTasks = finalList.filter((item) => !item.parent_id || item.parent_id === '')
      // get tasks with parents that are not in the list
      const rootSubTasks = finalList.filter(
        (item) =>
          item.parent_id &&
          item.parent_id !== '' &&
          !finalList.some((parentItem) => parentItem.task_id === item.parent_id)
      )
      // merge sub task with no parents to show in root level task list
      parentLevelTasks = [...parentLevelTasks, ...rootSubTasks]

      myTaskList.current = [...finalList]
      setTaskList(parentLevelTasks)

      // setTimeout(() => {
      //   onShowHistory('lusnpo10zbbf')
      // }, 1500)
    })

    const savedOptionShowCancelled = LocalSettings.getItem('optionShowRejected')
    if (savedOptionShowCancelled && savedOptionShowCancelled === 'true') {
      setOptionShowRejected(true)
    }
    // step.unsubscribe() step.subscribe when the component unmounts
    return () => {
      step.unsubscribe()
    }
  }, [])

  /** Does the current user have admin / manage access on the page */
  const hasManageAccess = () => {
    return false
  }

  /** Called when the manage button is pressed.  The user has manage access to
  the project and will show a special interface for the management functions */
  const handleManageButton = () => {
    setTaskList([])
    console.log('Calling search')
    step.processSearch('Admin,Complete,Drafs,OnHold,Cancelled')
  }

  const handleRefreshButton = () => {
    setTaskList([])
    step.processSearch()
  }

  let categories = allTask.map((task) => task.dash_category)
  categories = categories.filter((value, index, self) => {
    return self.indexOf(value) === index
  })

  categories = categories.sort((a, b) => {
    return getTaskListSettings(a).order - getTaskListSettings(b).order
  })

  /** Show a history window for the selected task */
  const onShowHistory = (task_id: string): void => {
    console.log('Showing history for task', task_id)
    for (const item of allTask) {
      if (item.task_id === task_id) {
        setShowHistoryForTask(item)
        return
      }
    }
  }

  /** Show a reassign window for the selected task */
  const onShowReassignModal = (task_id: string): void => {
    console.log('Showing reassign task', task_id)
    for (const item of allTask) {
      if (item.task_id === task_id) {
        setReassignTask(item)
        return
      }
    }
  }
  /** Toggles the subtasks slide window */
  // const toggleDrawer = () => {
  //   setSubShowingTask(undefined)
  // }

  const backToParent = (parent_id: string) => {
    const parentTask = allTask.filter((task) => task.task_id === parent_id)
    console.log('parentTask', parentTask)
    if (parentTask.length > 0) {
      setSubShowingTask(parentTask[0])
      setSubTaskList(allTask.filter((task) => parentTask[0]?.subtask_ids?.includes(task.task_id)))
    } else {
      setSubShowingTask(undefined)
    }
  }

  useEffect(() => {
    if (subShowingTask) {
      setSubTaskList(allTask.filter((task) => subShowingTask?.subtask_ids?.includes(task.task_id)))
    } else {
      setSubTaskList([])
    }
  }, [subShowingTask])

  const pluralizeIs = (count: number) => {
    if (count === 1) return `is ${count}`
    else return `are ${count}`
  }

  const pluralizeSubtask = (count: number) => {
    return count === 1 ? 'subtask' : 'subtasks'
  }

  const renderTaskBadges = (task: FindTaskResults) => {
    /** For Subtasks Badge Color,
     * Green -> It should display task related to status 1, 100 and 5000
     * Black -> It should display task related to status 900
     * Red   -> It should display task related to status 910,4500 and 6000 */
    return (
      <div className='d-flex'>
        {task.subtask_status_count.taskInProgress > 0 && (
          <div
            className='d-flex align-items-center position-relative me-3'
            data-tooltip-id='task-list-tooltip'
            data-tooltip-content={`There ${pluralizeIs(task.subtask_status_count.taskInProgress)} ${pluralizeSubtask(
              task.subtask_status_count.taskInProgress
            )} waiting to be completed`}
          >
            <FontAwesomeIcon icon={['fas', 'circle']} color='#4cb820' style={{ fontSize: '18px' }} />
            <div
              className='d-flex align-items-center justify-content-center'
              style={{ position: 'absolute', width: '18px', height: '18px', left: 0 }}
            >
              <span style={{ color: 'white' }}>{task.subtask_status_count.taskInProgress}</span>
            </div>
          </div>
        )}
        {task.subtask_status_count.taskClosed > 0 && (
          <div
            className='d-flex align-items-center position-relative me-3'
            data-tooltip-id='task-list-tooltip'
            data-tooltip-content={`There ${pluralizeIs(
              task.subtask_status_count.taskClosed
            )} already reviewed/closed ${pluralizeSubtask(task.subtask_status_count.taskClosed)}`}
          >
            <FontAwesomeIcon icon={['fas', 'circle']} color='#030303' style={{ fontSize: '18px' }} />
            <div
              className='d-flex align-items-center justify-content-center'
              style={{ position: 'absolute', width: '18px', height: '18px', left: 0 }}
            >
              <span style={{ color: 'white' }}>{task.subtask_status_count.taskClosed}</span>
            </div>
          </div>
        )}
        {task.subtask_status_count.taskRejected > 0 && (
          <div
            className='d-flex align-items-center position-relative me-3'
            data-tooltip-id='task-list-tooltip'
            data-tooltip-content={`There ${pluralizeIs(
              task.subtask_status_count.taskRejected
            )} rejected/expired/cancelled ${pluralizeSubtask(task.subtask_status_count.taskRejected)}`}
          >
            <FontAwesomeIcon icon={['fas', 'circle']} color='#d2494c' style={{ fontSize: '18px' }} />
            <div
              className='d-flex align-items-center justify-content-center'
              style={{ position: 'absolute', width: '18px', height: '18px', left: 0 }}
            >
              <span style={{ color: 'white' }}>{task.subtask_status_count.taskRejected}</span>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div key='task_list_wrapper'>
      {GlobalHashHelper.hasOption('debug') && <TaskListDebug items={taskList} />}
      <div className='task_list_wrapper'>
        <div className='title'>{step.getTitle()} </div>

        {hasManageAccess() && (
          <>
            <div
              className='icon-manage'
              data-testid='icon-manage'
              onClick={() => {
                handleManageButton()
              }}
            >
              Manage
            </div>
            <div className='iconBreak'></div>
          </>
        )}

        <div
          className='icon'
          data-testid='reload'
          onClick={() => {
            handleRefreshButton()
          }}
        >
          <FontAwesomeIcon icon={['fas', 'arrows-rotate']} />
        </div>

        <div className='iconBreak'></div>

        <div
          className='icon'
          data-testid='icon-list'
          onClick={() => {
            setDisplayMode('list')
            LocalSettings.taskDisplayMode = 'list'
          }}
        >
          <FontAwesomeIcon icon={['fas', 'braille']} color={displayMode === 'list' ? '#00a6b6' : '#253746'} />
        </div>

        <div
          className='icon'
          data-testid='icon-table'
          onClick={() => {
            setDisplayMode('table')
            LocalSettings.taskDisplayMode = 'table'
          }}
        >
          <FontAwesomeIcon icon={['fas', 'table']} color={displayMode === 'table' ? '#00a6b6' : '#253746'} />
        </div>

        <div className='iconBreak'></div>

        <div
          className='icon'
          data-testid='icon-table'
          onClick={(e) => {
            handleMoreOptionsMenu(e)
          }}
        >
          <FontAwesomeIcon icon={['fas', 'caret-down']} color='#253746' />
        </div>
      </div>
      {displayMode === 'list' && (
        <TaskListCards
          items={taskList}
          setSubShowingTask={setSubShowingTask}
          renderTaskBadges={renderTaskBadges}
          categories={categories}
          setContextTask={setContextTask}
          setContextMenuPosition={setContextMenuPosition}
        />
      )}
      {displayMode === 'table' && (
        <TaskListTable
          options={step.options}
          items={taskList}
          onShowHistory={onShowHistory}
          onShowReassignModal={onShowReassignModal}
          optionShowRejected={optionShowRejected}
          setSubShowingTask={setSubShowingTask}
          renderTaskBadges={renderTaskBadges}
        />
      )}
      {contextTask && (
        <TaskListButtonContextMenu top={contextMenuPosition.y} left={contextMenuPosition.x}>
          <ul>
            <li onClick={() => onShowHistory(contextTask.task_id)}>History of task</li>
            {/* <li onClick={() => onShowReassignModal(contextTask.task_id)}>Reassign task</li> */}
          </ul>
        </TaskListButtonContextMenu>
      )}
      {showHistoryForTask && <TaskHistory item={showHistoryForTask} onClose={() => setShowHistoryForTask(undefined)} />}
      {reassignTask && <TaskReassign item={reassignTask} logic={logic} onClose={() => setReassignTask(undefined)} />}

      <div
        className={'dropdown-background ' + (menuShowing ? 'dropdown-showing' : 'dropdown-hidden')}
        onClick={() => setMenuShowing(false)}
      ></div>

      <div
        className={'dropdown-menu dropdown-menu-sm ' + (menuShowing ? 'dropdown-showing' : 'dropdown-hidden')}
        style={{ top: menuPosition.y, left: menuPosition.x }}
        id='context-menu'
      >
        <a
          className='dropdown-item'
          onClick={() => {
            setOptionShowRejected(!optionShowRejected)
            LocalSettings.setItem('optionShowRejected', optionShowRejected ? 'false' : 'true')
            setMenuShowing(false)
            handleRefreshButton()
          }}
        >
          <FontAwesomeIcon icon={optionShowRejected ? ['fal', 'circle-check'] : ['fal', 'circle']} />
          <span style={{ marginLeft: '10px' }}>Show Rejected and Cancelled</span>
        </a>
      </div>
      <Tooltip id='task-list-tooltip' place={'top-end' as PlacesType} />
      <Drawer
        open={subShowingTask !== undefined}
        onClose={() => backToParent(subShowingTask && subShowingTask.parent_id ? subShowingTask.parent_id : '')}
        direction='right'
        size='60vw'
      >
        <div className='slide_task_list_wrapper'>
          <div className='title'>
            <FontAwesomeIcon
              icon={['fas', 'arrow-left']}
              onClick={() => backToParent(subShowingTask && subShowingTask.parent_id ? subShowingTask.parent_id : '')}
              style={{ cursor: 'pointer' }}
            />{' '}
            Subtasks of <b>{subShowingTask?.task_title}</b>{' '}
          </div>
          {displayMode === 'list' && (
            <TaskListCards
              items={subtaskList}
              setSubShowingTask={setSubShowingTask}
              renderTaskBadges={renderTaskBadges}
              categories={categories}
              setContextTask={setContextTask}
              setContextMenuPosition={setContextMenuPosition}
            />
          )}
          {displayMode === 'table' && (
            <TaskListTable
              options={step.options}
              items={subtaskList}
              onShowHistory={onShowHistory}
              onShowReassignModal={onShowReassignModal}
              optionShowRejected={optionShowRejected}
              setSubShowingTask={setSubShowingTask}
              renderTaskBadges={renderTaskBadges}
            />
          )}
        </div>
      </Drawer>
    </div>
  )
}

export default TaskList
