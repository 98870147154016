import React, { useState } from 'react'
import { LocalSettings } from '../utils/LocalSettings'

import { PageStepType } from '@digitalworkflow/dwtranslateclient'
import { LogicEngineTraceStep } from '@digitalworkflow/dwtranslateclient/lib/PageEngine/PageEngineDebugger'

const enableTraceStr = LocalSettings.getEnableTrace()

export type StepTraceItem = {
  event: string
  step: PageStepType
  trace: LogicEngineTraceStep
}

export type FormTraceItem = {
  event: string
  step: PageStepType
  trace: LogicEngineTraceStep
}

export interface IDataContext {
  showGearModal: boolean
  setShowGearModal: (val: boolean) => void
  enableTrace: boolean
  setEnableTrace: (val: boolean) => void
  logicValues: Array<any>
  setLogicValues: (val: Array<any>) => void
  stepTraceItems: Array<StepTraceItem>
  setStepTraceItems: (val: Array<StepTraceItem> | ((t: StepTraceItem[]) => StepTraceItem[])) => void
  formTraceItems: Array<StepTraceItem>
  setFormTraceItems: (val: Array<FormTraceItem> | ((t: FormTraceItem[]) => FormTraceItem[])) => void
}

const defaultState = {
  showGearModal: false,
  setShowGearModal: () => {},
  enableTrace: false,
  setEnableTrace: () => {},
  logicValues: [],
  setLogicValues: () => {},
  stepTraceItems: [],
  setStepTraceItems: () => {},
  formTraceItems: [],
  setFormTraceItems: () => {}
}

export const DataContext: React.Context<IDataContext> = React.createContext<IDataContext>(defaultState)

export const DataProvider = ({ children }: { children: any }): JSX.Element => {
  const [showGearModal, setShowGearModal] = useState<boolean>(false)
  const [enableTrace, setEnableTrace] = useState<boolean>(!!enableTraceStr || false)
  const [logicValues, setLogicValues] = useState<any>({})
  const [stepTraceItems, setStepTraceItems] = useState<StepTraceItem[]>([])
  const [formTraceItems, setFormTraceItems] = useState<FormTraceItem[]>([])

  const value = {
    showGearModal,
    setShowGearModal,
    enableTrace,
    setEnableTrace,
    logicValues,
    setLogicValues,
    stepTraceItems,
    setStepTraceItems,
    formTraceItems,
    setFormTraceItems
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export const useData = (): IDataContext => React.useContext(DataContext)
