import React, { useState, useEffect } from 'react'

import { FormFieldInputProps, useForm } from '../useForm'
import { LookupType, StringOptions } from '@digitalworkflow/dwtranslateclient'
import Combobox from 'react-widgets/Combobox'

import 'react-widgets/scss/styles.scss'
import './index.scss'

/**
 * AutoCompleteInput component
 *
 * @component AutoCompleteInput
 * @category FormElements
 */
const AutoCompleteInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, setValue, isEditFieldOnModal, isFieldDisabled, renderValidationError, getPlacholder } = useForm(
    {
      step,
      field,
      is_on_modal,
      is_disabled,
      error,
      warning,
      disabledFields,
      editValues,
      handleChangeEditValues,
      handleSetEditValues
    }
  )

  const [isClearable, setIsClearable] = useState(false)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const dateOptions = new StringOptions(field.option1)
    for (const key of dateOptions.Keys()) {
      if (key === 'isclearable') {
        setIsClearable(dateOptions.checkOption(key))
      }
    }
  }, [])

  useEffect(() => {
    setInputValue(isEditFieldOnModal() ? editValues[field.field] : myFieldValue)
  }, [editValues, myFieldValue])

  /**
   * Called when the user selects a value from the suggestions list
   *
   * @param item - Selected LookupType item.
   * @returns Void
   */
  const onSelected = (item: string | LookupType) => {
    console.log('onSuggestionSelected: ', item)
    if (typeof item === 'string') {
    } else {
      if (isEditFieldOnModal()) {
        handleChangeEditValues(field, item.code)
      } else {
        setValue(item.code)
      }
    }
  }

  /**
   * Called when the user types in the combobox
   *
   * @param item - Selected LookupType item.
   * @returns Void
   */
  const onChange = (item: string | LookupType) => {
    if (typeof item === 'string') {
      setInputValue(item)
    } else {
      if (isEditFieldOnModal()) {
        handleChangeEditValues(field, item.code)
      } else {
        setValue(item.code)
      }
    }
  }

  const clearInput = () => {
    onChange('')
  }

  if (step.tableDef) {
    /** Represents a lookup table */
    const list = step.tableDef?.getLookupTable(field.field)

    let myPlaceholder = getPlacholder()
    const myFind = list?.find((item) => item.code === getPlacholder())
    if (myFind && myFind.english && myFind.english !== '') myPlaceholder = myFind.english

    return (
      <div className={`${isFieldDisabled() ? 'flex-grow-1 disabled' : 'flex-grow-1'} combobox`}>
        <Combobox
          value={inputValue}
          data={list}
          dataKey='code'
          className='combobox-widget'
          disabled={isFieldDisabled()}
          textField={(item) => (typeof item === 'string' ? item : (item as LookupType).english)}
          filter='contains'
          name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
          onSelect={onSelected}
          placeholder={myPlaceholder}
          onChange={(value) => onChange(value)}
          data-testid={field.testid}
          inputProps={{
            className: 'form-control',
            id: `autocomplete-${field.field}`
          }}
          //   renderListItem={({ item }) => (
          //     <span>
          //       <strong>{item.english}</strong>
          //     </span>
          //   )}
        />
        {isClearable && inputValue && (
          <button className='react-datepicker__close-icon' aria-label='Close' tabIndex={-1} onClick={clearInput} />
        )}
        {renderValidationError()}
      </div>
    )
  }

  return <></>
}

export default AutoCompleteInput
