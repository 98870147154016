import formatHtml from 'sanitize-html'

export const isJson = (str: string): boolean => {
  if (!isNaN(parseFloat(str))) {
    return false
  }
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const sanitizeHtml = (unsafeHtml: string) => {
  return formatHtml(unsafeHtml, {
    allowedTags: ['a', 'del', 'div', 'ins', 'span', 'strong', 'p', 'ol', 'ul', 'li', 'br', 'b'],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
      span: ['class'],
      div: ['class'],
      strong: ['class'],
      p: ['class']
    }
  })
}

export const lastChar = (str: string) => {
  return str.split('').pop()
}
