import React from 'react'

import { BuildingSearch } from '@digitalworkflow/dwreactcommon'

import { FormFieldInputProps, useForm } from '../useForm'

import './index.scss'
import { StringOptions } from '@digitalworkflow/dwtranslateclient'

/**
 * BuildingSearchInput component
 *
 * @component BuildingSearchInput
 * @category FormElements
 */
const BuildingSearchInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, setValue, isFieldDisabled, renderValidationError, isEditFieldOnModal } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  /**
   * Used to change the value of the field.
   *
   * @param items - the array of building id
   * @returns Void
   */
  const handleChange = (items: (string | number)[]) => {
    const value = items.length ? items.join(',') : ''
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, value)
    } else {
      setValue(value)
    }
  }

  const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

  let myCustomFilter = ''
  const options = new StringOptions(field.option1)
  if (options.checkOption('CustomFilter')) {
    myCustomFilter = options.getValue('CustomFilter', '')
  }

  let myShowFMZID = false
  if (options.checkOption('showFMZID')) {
    myShowFMZID = options.getValue('showFMZID', true)
  }

  let myRegexpSel = ''
  if (options.checkOption('regexpSel')) {
    myRegexpSel = options.getValue('regexpSel', '/.*/')
  }

  return (
    <div className={isFieldDisabled() ? 'building-search disabled' : '' + ' building-search'}>
      <BuildingSearch
        showFilterPostcode
        showFMZID={myShowFMZID}
        otherFilters='FMZ, City'
        showFav={false}
        showNearest
        minSelect={1}
        maxSelect={10000}
        allowPaste
        showDistance
        customFilter={myCustomFilter}
        templateLine1='%ID% (%CLIENT_BUILDING_CODE%) | %BUILDING_NAME%'
        templateLine2='%ADDRESS%, %CITY%, %PROV% | %FMZ_DESCRIPTION%'
        clientIds='151'
        onChange={handleChange}
        regexpSel={myRegexpSel}
        value={fieldValue === '' ? [] : fieldValue.split(',')}
      />
      {renderValidationError()}
    </div>
  )
}

export default BuildingSearchInput
