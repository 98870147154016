import React from 'react'

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { Resizable, ResizeDirection, NumberSize } from 're-resizable'
import { LocalSettings } from '../../utils/LocalSettings'

interface IDraggableResizeable {
  children: React.ReactNode
  height?: number
  width?: number
  x?: number
  y?: number
  handleDragStop: (e: DraggableEvent, data: DraggableData) => void
  handleResizeStop: (
    event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    refToElement: HTMLElement,
    delta: NumberSize
  ) => void
}

const DraggableResizeable = ({
  children,
  width = 400,
  height = 550,
  x = 0,
  y = 0,
  handleDragStop,
  handleResizeStop
}: IDraggableResizeable) => {
  return (
    <Draggable handle='.handle' defaultPosition={{ x: x, y: y }} scale={1} onStop={handleDragStop}>
      <Resizable
        defaultSize={{
          width: width,
          height: height
        }}
        maxHeight={LocalSettings.traceWindowSettings.innerScreenHeight - 70}
        maxWidth={LocalSettings.traceWindowSettings.innerScreenWidth - 70}
        onResizeStop={handleResizeStop}
        className='bg-white d-flex flex-column'
      >
        {children}
      </Resizable>
    </Draggable>
  )
}
export default DraggableResizeable
