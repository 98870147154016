import React, { useEffect } from 'react'

import {
  FindTaskResults,
  WorkflowState,
  WorkflowStateCollection,
  getLogicData
} from '@digitalworkflow/dwtranslateclient'

export interface TaskListDebugProps {
  items: FindTaskResults[]
}

const row = (strTitle: string, value: string | number | boolean | undefined) => {
  return (
    <div className='debugRow'>
      <div className='left'> {strTitle} </div>
      <div className='right'> {getLogicData(value).toStringFormatted()} </div>
    </div>
  )
}

const rowBreak = () => {
  return (
    <>
      <div className='debugRowBreak'></div>
      <div className='debugRowBreak'></div>
    </>
  )
}

const TaskListDebug = ({ items }: TaskListDebugProps) => {
  // const [myDebugItems, setMyDebugItems] = React.useState<{ [key: string]: string }>({})
  const [workflowState, setWorkflowState] = React.useState<{ [key: string]: WorkflowState }>({})

  useEffect(() => {
    async function loadAllStates() {
      for (const item of items) {
        if (!workflowState[item.workflow_id]) {
          const ws = await WorkflowStateCollection.getWorkflowStateById(item.workflow_id)
          if (ws) {
            setWorkflowState((prev) => {
              return { ...prev, [item.workflow_id]: ws }
            })
          }
        }
      }
    }

    loadAllStates()
  }, [items])

  return (
    <div key='taskDebugList'>
      <div> Debug List for {items.length} items. </div>

      {items.map((item, index) => {
        return (
          <div className='debugItem' key={'debug' + index}>
            {row('Task ID:', item.task_id)}
            {row('Route', item.route)}
            {rowBreak()}
            {row('Task Title', item.task_title)}
            {row('Task Status Code:', item.task_status_id)}
            {row('Task Status Text:', item.task_status)}
            {rowBreak()}
            {row('Workflow ID:', item.workflow_id)}
            {row('Workflow Start Date:', item.workflow_start_dt.toStringFormatted())}
            {row('Workflow SLA Date:', item.workflow_sla_dt.toStringFormatted())}
            {rowBreak()}
            {row('Workflow Code:', item.workflow_code)}
            {row('Workflow Name:', item.workflow_name)}
            {row('Workflow Fullname:', item.workflow_fullname)}
            {row('Workflow Description:', item.workflow_description)}
            {rowBreak()}
            {row('Updated Date:', item.updated_dt.toStringFormatted())}
            {row('Updated By:', item.updated_by)}
            {row('Created Date:', item.create_dt.toStringFormatted())}
            {row('Create By:', item.create_by)}
            {rowBreak()}
            {row('Can Action:', item.can_action)}
            {row('Can See:', item.can_see)}
            {row('Can Manage:', item.can_manage)}
            {row('Open Query Count:', item.open_query_count)}
            {row('Dash Category:', item.dash_category || '')}
            {row('Assigned User:', item.auser)}

            {workflowState[item.workflow_id] && (
              <>
                {rowBreak()}
                {row('Workflow Module ID:', workflowState[item.workflow_id].data.module_id)}
                {row('Workflow Code:', workflowState[item.workflow_id].data.code)}
                {row('Workflow Description:', workflowState[item.workflow_id].data.description)}
                {row('Workflow Display Order:', workflowState[item.workflow_id].data.display_order)}
                {row('Workflow Display Order:', workflowState[item.workflow_id].data.display_order)}
                {row('Workflow Route:', workflowState[item.workflow_id].data.route)}
                {row('Workflow Route See:', workflowState[item.workflow_id].data.route_see)}
                {row('Workflow Route Manage:', workflowState[item.workflow_id].data.route_manage)}
                {row('Workflow Can See:', JSON.stringify(workflowState[item.workflow_id].data.wg_cansee))}
                {row('Workflow Can Action:', JSON.stringify(workflowState[item.workflow_id].data.wg_canaction))}
                {row('Workflow Can Manage:', JSON.stringify(workflowState[item.workflow_id].data.wg_canmanage))}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default TaskListDebug
