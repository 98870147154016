import styled from 'styled-components'

interface ContextMenuProps {
  top: number
  left: number
}

export const TaskListButtonContextMenu = styled.div<ContextMenuProps>`
  position: absolute;
  width: 200px;
  box-sizing: border-box;

  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.3);
  border: solid 1px #bdc3c7;
  background: #fff;
  border-radius: 2px;
  padding: 0;

  ul {
    box-sizing: border-box;
    padding: 10px;
    margin: 0;
    list-style: none;
  }
  ul li {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 0;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    background-color: #ecf0f1;
  }
`
