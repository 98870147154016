import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { BreadcrumbItem } from '../../types/breadcrumbs'
import './breadcrumbs.scss'

interface Props {
  breadcrumbItems: BreadcrumbItem[]
}

/**
 * BreadCrumbs component
 *
 * @component BreadCrumbs
 * @category PageElements
 */
const BreadCrumbs = ({ breadcrumbItems }: Props) => {
  const location = window.location.href
  return (
    <Row>
      <Col xs='12'>
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
          <h4 className='mb-0 font-size-18'>
            {breadcrumbItems.map((breadcrumbItem, i) => {
              return (
                <React.Fragment key={i}>
                  <Link
                    to={breadcrumbItem.url}
                    className={`breadcrumblink ${location === breadcrumbItem.url ? 'active' : ''}`}
                  >
                    {breadcrumbItem.text}
                  </Link>
                  {i < breadcrumbItems.length - 1 && <span>&nbsp;&gt;&nbsp;</span>}
                </React.Fragment>
              )
            })}{' '}
          </h4>
          {/* <div className='page-title-right'>
            <ol className='breadcrumb m-0'>
              <BreadcrumbItem>
                <Link to='#'>Page</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to='#'>Dashboard</Link>
              </BreadcrumbItem>
            </ol>
          </div> */}
        </div>
      </Col>
    </Row>
  )
}

export default BreadCrumbs
