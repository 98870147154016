import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * Props for [[`PageRedirect`]] component
 */
export interface PageRedirectProps {
  /** Text string */
  strNewPage: string
}

/**
 * PageRedirect component
 *
 * @component PageRedirect
 * @category PageElements
 */
const PageRedirect = ({ strNewPage }: PageRedirectProps) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(strNewPage)
  }, [])
  return <></>
}

export default PageRedirect
