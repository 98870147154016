import React from 'react'

import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'
import { useData } from '../../context/DataContext'
import './index.scss'
import { LocalSettings } from '../../utils/LocalSettings'

const LogicEngineValuesTab = () => {
  const { enableTrace, setEnableTrace, logicValues } = useData()
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div className='form-check mb-1 mr-3'>
          <input
            type='checkbox'
            id='trace'
            className='form-check-input'
            checked={enableTrace}
            onChange={(e) => {
              if (e.target.checked) {
                LocalSettings.setEnableTrace('true')
              } else {
                LocalSettings.removeEnableTrace()
              }
              setEnableTrace(e.target.checked)
            }}
          />
          <label className={`${'form-check-label'}`} htmlFor='trace'>
            Enable Trace
          </label>
        </div>
      </div>
      <div className='json-view'>
        <JsonView data={logicValues} shouldExpandNode={allExpanded} style={darkStyles} />
      </div>
    </div>
  )
}

export default LogicEngineValuesTab
