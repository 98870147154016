import { StepButton, getLogicData } from '@digitalworkflow/dwtranslateclient'

type AddButtonCallback = (helper: ActionBarHelper) => void

export type ActionBarHelperRecord = {
  opt: StepButton
  is_visible: boolean
  is_enabled: boolean
}

export class ActionBarHelper {
  buttonList: ActionBarHelperRecord[] = []

  listEventSetup: boolean = false

  // Callback for adding a button
  onAddButton: AddButtonCallback | undefined

  reset() {
    this.buttonList = []
  }

  async onLogicRunConditions() {
    let modified: boolean = false
    for (const button of this.buttonList) {
      const visibleCondition = button.opt.option_2
      const enabledCondition = button.opt.option_3

      if (visibleCondition !== '') {
        const resultVisible = await button.opt.logicRef?.processTextReplacement(visibleCondition)
        const boolVisible = getLogicData(resultVisible).isTrue()
        console.log('Action Visible[', visibleCondition, ']=', boolVisible)
        if (boolVisible !== button.is_visible) {
          button.is_visible = boolVisible
          modified = true
        }
      } else {
        button.is_visible = true
      }

      if (enabledCondition !== '') {
        const resultEnabled = await button.opt.logicRef?.processTextReplacement(enabledCondition)

        const boolEnabled = getLogicData(resultEnabled).isTrue()

        if (boolEnabled !== button.is_enabled) {
          button.is_enabled = boolEnabled
          modified = true
        }
      } else {
        button.is_enabled = true
      }
    }

    if (modified && this.onAddButton) {
      this.onAddButton(this)
    }
  }

  addButton(opt: StepButton) {
    this.buttonList.push({
      opt,
      is_visible: true,
      is_enabled: true
    })

    if (!this.listEventSetup && opt.logicRef) {
      this.listEventSetup = true
      opt.logicRef.evRunConditions.subscribe(this.onLogicRunConditions.bind(this))
    }

    this.onLogicRunConditions().then(() => {
      if (this.onAddButton) this.onAddButton(this)
    })
  }
}
