import React from 'react'
import { InputGroup, Input } from 'reactstrap'

import { FormFieldInputProps, useForm } from '../useForm'

/**
 * NumberInput component
 *
 * @component NumberInput
 * @category FormElements
 */
const NumberInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const {
    myFieldValue,
    isEditFieldOnModal,
    isFieldDisabled,
    handleChangeText,
    renderUnit,
    renderUnitPrefix,
    renderValidationError,
    getClassName,
    getPlacholder
  } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue
  const className = getClassName()

  return (
    <div className={isFieldDisabled() ? 'disabled' : ''}>
      <InputGroup>
        {renderUnitPrefix()}
        <Input
          className={className}
          type='number'
          placeholder={getPlacholder()}
          name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
          value={fieldValue}
          disabled={isFieldDisabled()}
          id={field.field}
          data-testid={field.testid}
          onChange={handleChangeText}
        />
        {renderUnit()}
        {renderValidationError()}
      </InputGroup>
    </div>
  )
}

export default NumberInput
