import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Container, Spinner } from 'reactstrap'
import { PageManager, PageEngine } from '@digitalworkflow/dwtranslateclient'
import { RenderHelper } from '../../components/RenderHelper'
import { AuthService } from '@digitalworkflow/dwloginclient'
import { projectName } from '../../constants'
import SyncModal from '../../components/SyncModal'
import Breadcrumbs from '../../components/Breadcrumns/Breadcrumbs'

import { BreadcrumbItem } from '../../types/breadcrumbs'
import { syncDatabase } from '../../utils/syncDatabase'
import { useData } from '../../context/DataContext'

const authService = new AuthService()

const SurveyLoader = () => {
  const location = useLocation()
  const [syncing, setSyncing] = useState(true)
  const [loading, setLoading] = useState(false)
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([])
  const [allComponents, setAllComponents] = useState<Array<JSX.Element>>([])
  const [width, setWidth] = useState(0)
  const { setLogicValues, setStepTraceItems, setFormTraceItems } = useData()
  const { magicKey } = useParams()

  /** Update the logic values for Trace functions
  TODO: This is not really the best way, we should load these values
  once the trace button is pressed and we need to account for sub member values.
  */
  const setPageLogicValues = (rend_helper: RenderHelper) => {
    const json = rend_helper.logicRef?.values.toJSON()
    if (json) {
      setLogicValues(json)
    }
  }

  const pageLoad = async () => {
    AuthService.setProjectName(projectName)
    const page: PageEngine = await PageManager.instance().findRoute(location.pathname)

    console.log(page)

    if (page && page.isValid()) {
      setStepTraceItems([])
      setFormTraceItems([])
      const rend_helper = new RenderHelper()
      setLoading(true)
      rend_helper.breadcrumbItems = breadcrumbItems
      await page.processPage(rend_helper)

      const list: JSX.Element[] = []
      const breadcrumbs: BreadcrumbItem[] = []

      rend_helper.currentTarget.forEach((e) => {
        list.push(e as JSX.Element)
      })
      setAllComponents(list)

      rend_helper.breadcrumbItems.forEach((e) => {
        breadcrumbs.push(e)
      })

      setPageLogicValues(rend_helper)

      setBreadcrumbItems(breadcrumbs)
      setLoading(false)
    }
  }

  useEffect(() => {
    const loginWithMagicKeyAndSyncDB = async (key: string) => {
      await authService.authenticateByKey(key)
      syncDatabase(setWidth, setSyncing)
    }
    if (magicKey) {
      loginWithMagicKeyAndSyncDB(magicKey)
    }
  }, [])

  useEffect(() => {
    if (!syncing) {
      pageLoad()
    }
  }, [syncing, location.pathname])

  return (
    <Container fluid>
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      {loading ? <Spinner color='primary' /> : allComponents}

      <SyncModal width={width} isOpen={syncing} />
      <div className='endBlock'>&nbsp;</div>
    </Container>
  )
}

export default SurveyLoader
