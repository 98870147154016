import React, { useRef, useMemo } from 'react'
import { Button } from 'reactstrap'
import { AgGridReact } from 'ag-grid-react'
import { StepTable, TimeAgo } from '@digitalworkflow/dwtranslateclient'
import { LicenseManager } from 'ag-grid-enterprise'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

import {
  ColDef,
  FirstDataRenderedEvent,
  GridSizeChangedEvent,
  RowClassParams,
  RowStyle,
  IRowNode
} from 'ag-grid-community'

import './index.scss'
import { useNavigate } from 'react-router-dom'

LicenseManager.setLicenseKey(
  'CompanyName=Brians Com LLC,LicensedGroup=Protovate-1,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-033265,SupportServicesEnd=26_November_2023_[v2]_MTcwMDk1NjgwMDAwMA==e06890fd3dc491144a33daab1bbf3843'
)

/**
 * Props for [[`PageGrid`]] component
 */
export interface PageGridProps {
  /** Step data. */
  step: StepTable
}

/**
 * PageGrid component
 *
 * @component PageGrid
 * @category PageElements
 */
const PageGrid = ({ step }: PageGridProps) => {
  const agGridRef = useRef<any>(null)
  const navigate = useNavigate()

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      unSortIcon: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      editable: true,
      resizable: true,
      minWidth: 140
    }
  }, [])

  /**
   * Grid cell renderer.
   *
   * @remarks
   * Used for rendering the grid cell values
   */
  const cellRenderFunc = (props: any, col: StepTable.Column) => {
    if (col.is_action) {
      if (col.type.checkOption('GoToRoute')) {
        return (
          <Button color='primary' size='sm' className='grid-link-btn' onClick={() => navigate(props.data.link)}>
            {props.value}
          </Button>
        )
      }
    }
    if (col.format === 'TimeAgo') {
      return TimeAgo.inWords(new Date(props.value))
    }
    if (props.value === 'undefined' || props.value === undefined || props.value === null) {
      // Prevent undefined render
      return ''
    }
    return props.value
  }

  const colDefs = step.columns.map((col: StepTable.Column, index: number) => {
    // console.log('colDef', col)

    let minWidth = 145
    if (col.text === 'Actions') minWidth = 90

    return {
      field: 'col' + index,
      headerName: col.text,
      sortable: true,
      unSortIcon: true,
      minWidth: minWidth,
      filter: 'agTextColumnFilter',
      // type: col.align == 'right' ? 'rightAligned' : 'centerAligned',
      cellStyle: {
        textAlign: col.align,
        display: 'flex',
        alignItems: 'center',
        justifyContent: col.align === 'right' ? 'flex-end' : col.align === 'center' ? 'center' : 'flex-start'
      },
      headerClass: 'ag-header-align-' + col.align,
      cellRenderer: (props: any) => {
        return cellRenderFunc(props, col)
      },
      floatingFilter: true
    }
  })

  const gridData = step.getFormattedData().map((row: any) => {
    let rowData = {}
    row.map((item: any, idx: number) => {
      rowData = {
        ...rowData,
        ['col' + idx]: item.text
      }

      if (item.link) {
        rowData = {
          ...rowData,
          link: item.link
        }
      }
    })
    // console.log('Row=', row)
    return rowData
  })

  const getRowStyle = (params: RowClassParams) => {
    const formattedData = step.getFormattedData()

    if (params && params.node) {
      const node: IRowNode = params.node
      const rowIndex = node.rowIndex ?? 0

      if (formattedData[rowIndex][0].bgColor === 'Active') {
        return { background: '#dadada' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Success') {
        return { background: '#c8e5cb' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Default') {
        return { background: '#ffffff' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Primary') {
        return { background: '#bfd9fe' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Warning') {
        return { background: '#fbefba' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Info') {
        return { background: '#c5e5eb' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Danger') {
        return { background: '#efc7cb' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Secondary') {
        return { background: '#dddee2' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Light') {
        return { background: '#fefefe' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Dark') {
        return { background: '#c7c9ca' } as RowStyle
      }
    }
    return {}
  }

  const onGridReady = async (params: any) => {
    resizeGridColumns(params)
  }

  /** Resize grid columns to auto fit within the screen width */
  const resizeGridColumns = (params: GridSizeChangedEvent) => {
    const gridApi = params.api
    gridApi.sizeColumnsToFit()
    const allColumnIds: any[] = []
    const columnApi = params.columnApi
    if (columnApi) {
      columnApi.getColumns()?.forEach((column: any) => {
        if (column.getId() === 'Participant ID' || column.getId() === 'PID') {
          allColumnIds.push(column.getId())
        }
      })
      columnApi.autoSizeColumns(allColumnIds, false)
    }
  }

  /** On screen width change, readjust the grid columns width */
  const onGridViewportResize = (params: GridSizeChangedEvent) => {
    resizeGridColumns(params)
  }

  return (
    <div className='ag-theme-balham grid-table'>
      <AgGridReact
        ref={agGridRef}
        columnDefs={colDefs}
        onFirstDataRendered={(params: FirstDataRenderedEvent) => {
          params.api.sizeColumnsToFit()
        }}
        defaultColDef={defaultColDef}
        rowSelection='multiple'
        enableRangeSelection
        rowData={gridData}
        pagination={false}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridViewportResize}
        getRowStyle={getRowStyle}
      />
    </div>
  )
}

export default PageGrid
