import React, { useEffect, useState, useRef } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import DraggableResizeable from '../DraggableResizeable'
import './index.scss'
import style from '../PageEngine/PageTabs/index.module.scss'
import LogicEngineValues from './LogicEngineValuesTab'
import StepTraceTab from './StepTraceTab'
import FormTraceTab from './FormTraceTab'
import jsep from 'jsep'
import { useData, StepTraceItem, FormTraceItem } from '../../context/DataContext'
import { WindowSettings } from '../../types/windowSettings'
import { LocalSettings } from '../../utils/LocalSettings'

import { PageEngineDebugger, PageStepType, PageEngine, LogicEngineDataBase } from '@digitalworkflow/dwtranslateclient'

import {
  LogicEngineTraceStep,
  LogicEngineBinaryDebug,
  PageEngineDocumentChange
} from '@digitalworkflow/dwtranslateclient/lib/PageEngine/PageEngineDebugger'
import { DraggableData, DraggableEvent } from 'react-draggable'
import { ResizeDirection, NumberSize } from 're-resizable'

interface ITraceFeatureProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const TraceFeature = ({ isOpen, setIsOpen }: ITraceFeatureProps) => {
  const { enableTrace, stepTraceItems, setStepTraceItems, formTraceItems, setFormTraceItems } = useData()
  const [windowSettings, setWindowSettings] = useState<WindowSettings>(
    LocalSettings.traceWindowSettings as WindowSettings
  )
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const showDebug = false
  const modalRef = useRef<HTMLDivElement>(null)
  const tabs = [
    {
      text: 'Logic Engine Value',
      Component: <LogicEngineValues />
    },
    {
      text: 'Form Trace',
      Component: <FormTraceTab traceItems={formTraceItems} modalRef={modalRef} />
    },
    {
      text: 'Step Trace',
      Component: <StepTraceTab traceItems={stepTraceItems} modalRef={modalRef} />
    }
  ]

  const toggle = () => {
    setIsOpen(!isOpen)
    setStepTraceItems([])
    setFormTraceItems([])
  }

  const toggleCustomTab = (tab: number) => {
    if (activeTabIndex !== tab) {
      setActiveTabIndex(tab)
    }
  }

  useEffect(() => {
    PageEngineDebugger.stepStartCallback = (step: PageStepType, page: PageEngine) => {
      if (enableTrace) {
        console.log('-- PageEngineDebugger stepStartCallback Function:', step, page)
        setStepTraceItems((t) => [
          ...t,
          {
            event: 'start',
            step
          } as StepTraceItem
        ])
      }
    }

    PageEngineDebugger.stepEndCallback = (step: PageStepType, page: PageEngine) => {
      if (enableTrace) {
        console.log('-- PageEngineDebugger stepEndCallback Function:', step, page)
        setStepTraceItems((t) => [
          ...t,
          {
            event: 'end',
            step
          } as StepTraceItem
        ])
      }
    }

    PageEngineDebugger.stepDocumentChangeCallback = (change: PageEngineDocumentChange) => {
      if (enableTrace) {
        console.log('-- PageEngineDebugger stepDocumentChangeCallback Function:', change)
      }
    }

    PageEngineDebugger.stepLogicParseCallback = (
      strText: string,
      tree: jsep.Expression,
      result: LogicEngineDataBase
    ) => {
      if (enableTrace) console.log('-- PageEngineDebugger stepLogicParseCallback Function:', strText, tree, result)
    }

    PageEngineDebugger.stepBinaryFunctionCallback = (opt: LogicEngineBinaryDebug) => {
      if (enableTrace) console.log('-- PageEngineDebugger stepBinaryFunctionCallback Function:', opt)
    }

    PageEngineDebugger.stepLogicFunctionCallback = (opt: LogicEngineTraceStep) => {
      if (enableTrace) {
        console.log('-- PageEngineDebugger Logic Function:', opt)
        setStepTraceItems((t) => [
          ...t,
          {
            event: 'Trace',
            trace: opt
          } as StepTraceItem
        ])
        setFormTraceItems((t) => [
          ...t,
          {
            event: 'Trace',
            trace: opt
          } as FormTraceItem
        ])
      }
    }

    PageEngineDebugger.stepPageLoaded = (opt: { page: PageEngine }) => {
      if (enableTrace) console.log('-- PageEngineDebugger stepPageLoaded Function:', opt)
    }
  }, [enableTrace])

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    if (showDebug) console.log(e)
    const newSettings = {
      width: windowSettings.width,
      height: windowSettings.height,
      x: data.x,
      y: data.y,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      innerScreenHeight: window.innerHeight,
      innerScreenWidth: window.innerWidth
    }
    LocalSettings.traceWindowSettings = newSettings
    setWindowSettings(newSettings)
  }

  const handleResizeStop = (
    event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    refToElement: HTMLElement,
    delta: NumberSize
  ) => {
    if (showDebug) console.log(event, direction, refToElement, delta)

    const newSettings = {
      width: windowSettings.width + delta.width,
      height: windowSettings.height + delta.height,
      x: windowSettings.x,
      y: windowSettings.y,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      innerScreenHeight: window.innerHeight,
      innerScreenWidth: window.innerWidth
    }

    console.log(window.screen)

    // if (direction === 'topLeft') {
    //   newSettings.x = windowSettings.x - delta.width
    //   newSettings.y = windowSettings.y - delta.height
    // } else if (direction === 'topRight') {
    //   newSettings.y = windowSettings.y - delta.height
    // } else if (direction === 'bottomLeft') {
    //   newSettings.x = windowSettings.x - delta.width
    // }

    LocalSettings.traceWindowSettings = newSettings
    setWindowSettings(newSettings)
  }

  return (
    <>
      {isOpen && (
        <div>
          <DraggableResizeable
            width={windowSettings.width}
            height={windowSettings.height}
            x={windowSettings.x}
            y={windowSettings.y}
            handleDragStop={handleDragStop}
            handleResizeStop={handleResizeStop}
          >
            <div className='handle modal-header'>
              Trace Feature
              <button type='button' className='btn-close' aria-label='Close' onClick={toggle}></button>
            </div>
            <div className='overflow-auto modal-content' ref={modalRef}>
              <Nav tabs className={`nav-tabs-custom nav-justified ${style.tabList}`}>
                {tabs.map((tab, index: number) => (
                  <NavItem key={index} className={`${style.tabContainer} tabContainer`}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${activeTabIndex === index ? `active ${style.active}` : ''} ${style.tab} tab`}
                      onClick={() => {
                        toggleCustomTab(index)
                      }}
                    >
                      <span className='d-none d-sm-block'>{tab.text}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <div className='mt-2'>{tabs[activeTabIndex].Component}</div>
            </div>
          </DraggableResizeable>
        </div>
      )}
    </>
  )
}
export default TraceFeature
