import React from 'react'
import { InputGroup, Input } from 'reactstrap'

import { FormFieldInputProps, useForm } from '../useForm'

/**
 * CurrencyInput component
 *
 * @component CurrencyInput
 * @category FormElements
 */
const CurrencyInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const {
    myFieldValue,
    isEditFieldOnModal,
    isFieldDisabled,
    renderUnit,
    renderUnitPrefix,
    renderValidationError,
    getClassName,
    getPlacholder,
    setValue
  } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue
  const className = getClassName()

  /**
   * Used to change the value of a field.
   *
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeCurrency = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    const validated = text.match(/^(\d*\.{0,1}\d*$)/)
    if (!validated) return
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <div className={isFieldDisabled() ? 'flex-grow-1 disabled' : 'flex-grow-1'}>
      <InputGroup>
        {renderUnitPrefix()}
        <Input
          className={className}
          type='text'
          placeholder={getPlacholder()}
          name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
          value={fieldValue}
          disabled={isFieldDisabled()}
          id={field.field}
          data-testid={field.testid}
          onChange={handleChangeCurrency}
        />
        {renderUnit()}
        {renderValidationError()}
      </InputGroup>
    </div>
  )
}

export default CurrencyInput
