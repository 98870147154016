import React from 'react'

import { FormFieldInputProps, useForm } from '../useForm'
import { LookupType } from '@digitalworkflow/dwtranslateclient'

import './index.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * LookupInput component
 *
 * @component LookupInput
 * @category FormElements
 */
const LookupInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, setValue, isEditFieldOnModal, isFieldDisabled, renderValidationError } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  /**
   * Used to change the value of a radio field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
    }
  }

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  const getLookupItemText = (item: LookupType) => {
    return item?.text_en
  }

  if (step.tableDef) {
    /** Represents a lookup table */
    const list = step.tableDef?.getLookupTable(field.field)
    const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

    return (
      <div className={isFieldDisabled() ? 'disabled' : ''}>
        {list
          ? list.map((item: LookupType) => {
              return (
                <div className='form-check mb-1' key={item.code}>
                  <input
                    type='radio'
                    id={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                    className='form-check-input radio-input'
                    name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
                    value={item.code}
                    data-testid={field.testid + '_' + item.code}
                    checked={fieldValue === item.code}
                    disabled={isFieldDisabled()}
                    onChange={handleChangeRadio}
                  />
                  <label
                    className={`${isFieldDisabled() ? 'form-check-label disabled' : 'form-check-label'} ${
                      fieldValue === item.code ? 'selected-label' : ''
                    }`}
                    htmlFor={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                  >
                    {htmlToReactParser.parse(getLookupItemText(item))}
                  </label>
                </div>
              )
            })
          : ''}
        {renderValidationError()}
      </div>
    )
  }

  return <></>
}

export default LookupInput
