import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { LookupType } from '@digitalworkflow/dwtranslateclient'

import { FormFieldInputProps, useForm } from '../useForm'

/**
 * AutoSuggestInput component
 *
 * @component AutoSuggestInput
 * @category FormElements
 */
const AutoSuggestInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, isEditFieldOnModal, isFieldDisabled, setValue, renderValidationError } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const [suggestions, setSuggestions] = useState<LookupType[]>([])

  /**
   * Used to change the value of a field.
   *
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeAutoSuggestInput = (e: any) => {
    if (e.target.value === undefined) {
      return
    }
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
    }
  }

  /**
   * Called when the user selectes a value from the suggestions list
   *
   * @param event - Event data.
   * @param suggestion - Suggestion object
   * @param suggestionValue - Suggestion string value
   * @returns Void
   */
  const onSuggestionSelected = (event: any, { suggestion, suggestionValue }: any) => {
    console.log('onSuggestionSelected: ', event, suggestion)
    if (step && step.tableDef) {
      /** Represents the lookup table */
      const list = step.tableDef?.getLookupTable(field.field)

      /** Represents the first item in the list which english text is equal to value. */
      const selected = list.find((item) => item.english === suggestionValue)

      if (isEditFieldOnModal()) {
        handleChangeEditValues(field, selected ? selected.code : suggestionValue)
      } else {
        // step.setValueFromUser(
        //   field.field,
        //   selected ? selected.code : suggestionValue
        // )
        setValue(selected ? selected.code : suggestionValue)
      }
    }
  }

  const onSuggestionsFetchRequested = ({ value }: any) => {
    getSuggestions(value)
  }

  const onSuggestionsClearRequested = () => {
    getSuggestions('')
  }

  const getSuggestions = (value: any) => {
    if (step.tableDef) {
      const list = step.tableDef?.getLookupTable(field.field)
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      return setSuggestions(
        inputLength === 0
          ? list
          : list.filter((lang: any) => lang.english.toLowerCase().slice(0, inputLength) === inputValue)
      )
    }

    setSuggestions([])
  }

  const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

  return (
    <>
      <div className='d-inline-block mr-2 mb-1'>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={(item: any) => item.english}
          onSuggestionSelected={onSuggestionSelected}
          shouldRenderSuggestions={(value, reason) => {
            return value.trim().length > 0 || reason === 'input-focused' || reason === 'render'
          }}
          renderSuggestion={(item: any, { isHighlighted }) => {
            return (
              <div
                key={item.english}
                style={{
                  background: isHighlighted ? 'lightgray' : 'white',
                  padding: '5px'
                }}
              >
                {item.english}
              </div>
            )
          }}
          inputProps={{
            className: 'form-control autocomplete',
            id: `autosuggest-${field.field}`,
            disabled: isFieldDisabled(),
            name: isEditFieldOnModal() ? 'edit_' + field.field : field.field,
            value: fieldValue,
            onChange: (e: any) => {
              handleChangeAutoSuggestInput(e)
            }
          }}
        />
      </div>
      {renderValidationError()}
    </>
  )
}

export default AutoSuggestInput
