import React, { useState } from 'react'
import { Form, Modal } from 'reactstrap'

import { SyncableQueryType } from '@digitalworkflow/dwtranslateclient'

/**
 * Props for [[`QueryCloseModal`]] component
 */
interface ModalProps {
  isShowQueryCloseModal: boolean | undefined
  handleQueryClose: (reply: string) => void
  toggleQueryCloseModal: () => void
  queryRecord: SyncableQueryType | undefined
  is_edit_mode: boolean
  is_query_mode: boolean
}

/**
 * QueryCloseModal component
 *
 * @component QueryCloseModal
 * @category FormElements
 */
const QueryCloseModal = ({
  isShowQueryCloseModal,
  handleQueryClose,
  toggleQueryCloseModal,
  queryRecord
}: ModalProps) => {
  const [message, setMessage] = useState<string>('')
  //   const [error, setError] = useState('')

  const handleCloseModal = () => {
    setMessage('')
    // setError('')
    toggleQueryCloseModal()
  }

  const handleSave = () => {
    handleQueryClose(message.trim())
    // if (message === '') {
    //     setError('This field is required and may not be left empty.')
    // } else {
    //   handleQueryClose(message.trim())
    //   setMessage('')
    //     setError('')
    // }
  }

  return (
    <Modal size='lg' isOpen={isShowQueryCloseModal}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Query Close</h5>
        <button type='button' onClick={handleCloseModal} className='close' data-dismiss='modal' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        {queryRecord && (
          <div id='query-form'>
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
              }}
            >
              <div className='col-md-12'>
                <label className='col-md-12 col-form-label'>Would you like to mark this as resolved?</label>
                {/* <div className='col-md-12'>
                  <InputGroup>
                    <input
                      className='form-control'
                      name='message'
                      value={message}
                      type='text'
                      onChange={(e: any): void => {
                        setMessage(e.target.value)
                      }}
                    />
                  </InputGroup>
                </div>
                <div className='col-md-12'>
                  {error && <span className='validation-error'>{error}</span>}
                </div> */}
              </div>
            </Form>
          </div>
        )}
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-primary waves-effect waves-light' onClick={handleSave}>
          Confirm
        </button>
      </div>
    </Modal>
  )
}

export default QueryCloseModal
