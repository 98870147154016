import React, { useEffect, useState } from 'react'

import { StepForm } from '@digitalworkflow/dwtranslateclient'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * Props for each FormField Input component
 */
export interface FormFieldInputProps {
  /** Step data. */
  step: StepForm
  /** Form field info */
  field: StepForm.FieldInfo
  /** True if form is on modal */
  is_on_modal: boolean
  /** Optional, true if form is disabled. */
  is_disabled?: boolean
  /** Form field error message */
  error: string
  /** Form field warning message */
  warning: string
  /** Array of disabled field names */
  disabledFields: Array<string>
  /** Edit field values */
  editValues: any
  /** Edit field value change handler */
  handleChangeEditValues: (field: StepForm.FieldInfo, value: string) => void
  handleSetEditValues: (field: StepForm.FieldInfo, value: string, placeholder: string) => void
}

export const useForm = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const [myUnits] = useState(step.getValueUnits(field.field))
  const [myFieldValue, setMyFieldValue] = useState('')
  // const [error, setError] = useState('')
  // const [warning, setWarning] = useState('')

  useEffect(() => {
    const value = getValue()
    handleSetEditValues(field, value, getPlacholder())
    setMyFieldValue(value)
  }, [])

  const myAlign = step.getValueAlign(field.field)

  /** Return input field classname */
  const getClassName = () => {
    let className = 'form-control'
    if (myAlign === 'right') className += ' text-end'
    if (error && !is_disabled && (is_on_modal || !step.is_edit_mode)) {
      className += ' is-invalid'
    }
    return className
  }

  /** Get the value from the record using getValueDatabase and return a clean/trimmed version */
  const getValue = (): string => {
    const myValueDatabase = step.getValueDatabase(field.field)

    let value = ''
    if (myValueDatabase !== undefined && myValueDatabase !== null) {
      if (typeof myValueDatabase === 'string') value = myValueDatabase.trim()
      else if (myValueDatabase.toString) value = myValueDatabase.toString()
      else value = myValueDatabase as any
    }
    return value
  }

  /** Set the value that should appear in the editor to the string 'newValue' and
   * set the form value at the same time.  Skips setting the form value if the user
   * enters something ending in "." so that 0. or .03 or something similar can be entered.
   */
  const setValue = (newValue: string) => {
    setMyFieldValue(newValue)

    // not sure why we need this code? because of this, copy/page string with ending '.' letters not working
    // need to be reviewed by Bernie
    // if (newValue.length > 0) {
    //   if (newValue.charAt(newValue.length - 1) === '.') return
    // }
    step.setValueFromUser(field.field, newValue)
    handleSetEditValues(field, newValue, getPlacholder())
  }

  const isEditFieldOnModal = () => {
    return is_on_modal && !is_disabled
  }

  /**
   * Returns true if edit mode is true and if is on modal is false
   *
   * @returns
   * Boolean
   */
  const isEditMode = () => {
    return !is_on_modal && step.is_edit_mode
  }

  /**
   * Returns true if edit mode is true and if is_on_modal is false
   *
   * @returns
   * Boolean
   */
  const isFieldDisabled = () => {
    const isFieldDisabled = is_disabled
    return isFieldDisabled
  }

  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setMyFieldValue(e.target.value)
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
      // handleSetEditValues(field, e.target.value, getPlacholder())
    }
  }

  /**
   * Cleans/trims value of a provided field and sets it as the new value.
   *
   * @param field - Points to a field.
   * @returns Void
   */
  const handleTrimText = () => {
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, editValues[field.field].trim())
    } else {
      const value = getValue()
      setValue(value)
    }
  }

  /**
   * Renders units of the field field.
   */
  const renderUnit = (): JSX.Element => {
    if (myUnits) {
      return (
        <span className='input-group-append'>
          <span className='input-group-text endcap'>
            <i>{myUnits}</i>
          </span>
        </span>
      )
    }
    return <></>
  }

  const renderUnitPrefix = (): JSX.Element => {
    if (myUnits === 'CAD' || myUnits === 'USD') {
      return (
        <span className='input-group-prepend'>
          <span className='input-group-text endcap'>
            <i> $ </i>
          </span>
        </span>
      )
    }

    if (myUnits === 'GBP') {
      return (
        <span className='input-group-prepend'>
          <span className='input-group-text endcap'>
            <i> £ </i>
          </span>
        </span>
      )
    }
    return <></>
  }

  const renderValidationError = () => {
    if (is_disabled || field.enabled || (!is_on_modal && step.is_edit_mode)) {
      return <></>
    }
    return (
      <>
        {error && <span className='invalid-feedback'>{htmlToReactParser.parse(error)}</span>}
        {warning && <span className='warning-feedback'>{htmlToReactParser.parse(warning)}</span>}
      </>
    )
  }

  const getPlacholder = () => {
    // Returns a LogicEngineDataBase which could be empty
    const objPlaceholder = step.getPlaceholderValue(field)
    let txtPlaceholder = ''
    if (objPlaceholder.value !== undefined) {
      txtPlaceholder = objPlaceholder.toString()
    }
    return txtPlaceholder
  }

  return {
    myFieldValue,
    getValue,
    setValue,
    isEditFieldOnModal,
    isFieldDisabled,
    handleChangeText,
    handleTrimText,
    renderUnit,
    renderUnitPrefix,
    renderValidationError,
    getClassName,
    isEditMode,
    getPlacholder
  }
}
