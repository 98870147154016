import { MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'
import { AuthService } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../utils/LocalSettings'

const authService = AuthService.instance()

export const getEnv = (): 'staging' | 'prod' | 'dev' | 'local' => {
  const _env = (process.env.REACT_APP_ENV || '').toLowerCase().trim()
  console.log('getEnv = ', _env, ' and ', process.env.REACT_APP_ENV)
  if (_env === 'staging') return 'staging'
  if (_env === 'prod') return 'prod'
  if (_env === 'production') return 'prod'
  if (_env === '') return 'local'
  if (_env === 'local') return 'local'
  return 'dev'
}

export const getSearchEnv = () => {
  if (getEnv() === 'staging') return 'staging'
  if (getEnv() === 'prod') return 'production'
  if (getEnv() === 'local') return 'local'
  return 'dev'
}

/** Return true if the  */
export const getIORPS = () => {
  if (window.location.hostname?.indexOf('rps') !== -1) return true
  if (window.location.hostname?.startsWith('io')) return true
  if (window.location.hash && window.location.hash.indexOf('rps') !== -1) return true
  if (window.location.pathname.startsWith('/io/')) return true
  return false
}

export const getLoginPortalID = () => {
  /** IO RPS / Digital Workflow */
  if (getIORPS()) return '4LvIsf9k'
  /** Digital Workflow Normal */
  return '3PvIsf5l'
}

/** Returns true in the production environment */
export const getIsProduction = () => {
  return getEnv() === 'prod'
}

/** Return the login portal URL without the trailing slash */
export const getLoginPortalHost = () => {
  if (getEnv() === 'staging') return 'https://stg-login.bgis.com'
  if (getEnv() === 'prod') return 'https://dw-login.bgis.com'
  // Default to Protovate Dev Server
  return 'https://logindev.dw.protovate.com'
}

export const redirectToLogin = () => {
  console.log('Redirecting to Login Portal')
  authService.authLogout()
  LocalSettings.removePortalAuthToken()
  LocalSettings.removePortalUser()
  window.location.href = `${getLoginPortalHost()}/login/${getLoginPortalID()}?return=` + window.location.hostname
}

// export const isIORPS = window.location.hostname?.indexOf('rps') !== -1 || window.location.hostname?.startsWith('io')
// export const loginPortalId = isIORPS ? '4LvIsf9k' : '3PvIsf5l'
// export const loginPortalHost =
// getEnv() === 'staging' ? 'https://stg-login.bgis.com' : 'https://logindev.dw.protovate.com'

export const projectName = 'dwportal'

export const menuItems: Array<MenuItem> = getIsProduction()
  ? [
      {
        item_type: 'Label',
        title_en: 'MY WORKFLOW',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Dashboard',
        icon: 'fa-light fa-table-columns',
        view_groups: 'PI, PSI',
        route: '/page/dashboard',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Task Inbox',
        icon: 'fa-light fa-arrow-down-to-arc',
        view_groups: 'PI, PSI',
        route: '/page/tasks',
        children: []
      },
      {
        item_type: 'Label',
        title_en: 'MODULES',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Digital Forms',
        icon: 'fa-solid fa-book-atlas',
        view_groups: 'PI, PSI',
        route: '/page/digital_forms',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Force Update',
        icon: 'fa fa-refresh',
        view_groups: 'PI, PSI',
        route: '/force-update',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'System Access',
        icon: 'fa fa-user',
        view_groups: 'PI, PSI',
        route: '/test-accounts',
        children: []
      }
    ]
  : [
      {
        item_type: 'Label',
        title_en: 'MY WORKFLOW',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Dashboard',
        icon: 'fa-light fa-table-columns',
        view_groups: 'PI, PSI',
        route: '/page/dashboard',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Task Inbox',
        icon: 'fa-light fa-arrow-down-to-arc',
        view_groups: 'PI, PSI',
        route: '/page/tasks',
        children: []
      },
      {
        item_type: 'Label',
        title_en: 'MODULES',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Digital Forms',
        icon: 'fa-solid fa-book-atlas',
        view_groups: 'PI, PSI',
        route: '/page/digital_forms',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Force Update',
        icon: 'fa fa-refresh',
        view_groups: 'PI, PSI',
        route: '/force-update',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Test Accounts',
        icon: 'fa fa-user',
        view_groups: 'PI, PSI',
        route: '/test-accounts',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Settings',
        icon: 'fa fa-cog',
        view_groups: 'PI, PSI',
        route: 'page/settings',
        children: []
      }
      // {
      //   item_type: 'Link',
      //   title_en: 'Lookup Manager',
      //   icon: 'fa-solid fa-list-check',
      //   view_groups: 'PI, PSI',
      //   route: '/lookup-manager',
      //   children: []
      // }
    ]

export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: () => {
      authService.authLogout()
      LocalSettings.removePortalUser()
      LocalSettings.removePortalAuthToken()
      window.location.href = `${getLoginPortalHost()}/login/${getLoginPortalID()}${
        window.location.host.match(/local/) ? '?return=local' : ''
      }`
    }
  }
]
