import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const TaskManager = () => {
  const [myTaskID, setMyTaskID] = useState<string>('')

  const location = useLocation()

  useEffect(() => {
    const urlSegments = location.pathname.split('/manage')
    setMyTaskID(urlSegments.join('---'))
  }, [])

  return <>Soon: {myTaskID}</>
}

export default TaskManager
