import React, { useEffect } from 'react'
import { deleteDatabase } from '../../utils/syncDatabase'
import { getLoginPortalHost, getLoginPortalID } from '../../constants'
import { AuthService } from '@digitalworkflow/dwloginclient'

const authService = AuthService.instance()

const Reload = () => {
  useEffect(() => {
    const clearAll = async () => {
      await deleteDatabase()
      authService.authLogout()
      localStorage.clear()
      window.location.href = `${getLoginPortalHost()}/logout/${getLoginPortalID()}?return=` + window.location.hostname
    }

    clearAll()
  }, [])

  return <></>
}

export default Reload
