import React from 'react'

import { FileAttachment } from '@digitalworkflow/dwreactcommon'
import { FieldError, ProjectDocumentDocumentType } from '@digitalworkflow/dwtranslateclient'

import { FormFieldInputProps, useForm } from '../useForm'
import { RenderHelper } from '../../../RenderHelper'

type AttachmentsInputProps = FormFieldInputProps & {
  renderer: RenderHelper
}

/**
 * AttachmentsInput component
 *
 * @component AttachmentsInput
 * @category FormElements
 */
const AttachmentsInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues,
  renderer
}: AttachmentsInputProps) => {
  const { isFieldDisabled, renderValidationError, setValue, isEditFieldOnModal } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const handleChange = async (files: Partial<ProjectDocumentDocumentType>[]) => {
    const fileIds = files.map((file) => file.id)
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, fileIds.join(','))
    } else {
      setValue(fileIds.join(','))
      /** Save the form immediately when the files are uploaded/removed */
      const result: FieldError[] = await renderer.submitForm(true)
      console.log('Save Validation Result: ', result)
    }
  }

  return (
    <div className='flex-grow-1 overflow-hidden'>
      <FileAttachment
        disabled={isFieldDisabled()}
        projectName={step.record?._data.project_id}
        onChange={handleChange}
        ref_id={step.logicRef?.getValue('task_id')}
        extensions={[
          '.jpeg',
          '.jpg',
          '.png',
          '.mp4',
          '.tif',
          '.tiff',
          '.docx',
          '.doc',
          '.dwg',
          '.mpp',
          '.pdf',
          '.xlsx',
          '.xls',
          '.msg',
          '.pptx',
          '.ppt'
        ]}
      />
      {renderValidationError()}
    </div>
  )
}

export default AttachmentsInput
