import React, { useEffect } from 'react'
import { redirectToLogin } from '../../constants'

const ForceLogout = () => {
  useEffect(() => {
    redirectToLogin()
  }, [])

  return <div key='logout'>Logout Complete</div>
}

export default ForceLogout
