import { WorkgroupType } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from './LocalSettings'

const adminDeveloper = ['DigitalWorkflow :: Admin', 'DigitalWorkflow :: Developer']

export const checkAdminDeveloper = () => {
  const userInfoString = LocalSettings.getPortalUser()
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString)
    return userInfo.work_groups.some((workgroup: WorkgroupType) =>
      adminDeveloper.includes(workgroup.formatted_workgroup_name ?? '')
    )
  } else {
    return false
  }
}
