import React from 'react'

import { FormFieldInputProps, useForm } from '../useForm'
import { LookupType } from '@digitalworkflow/dwtranslateclient'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * Dropdown component
 *
 * @component Dropdown
 * @category FormElements
 */
const Dropdown = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const {
    myFieldValue,
    setValue,
    isFieldDisabled,
    isEditFieldOnModal,
    renderValidationError,
    getClassName,
    getPlacholder
  } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.value)
    } else {
      setValue(e.target.value)
    }
  }

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  const getLookupItemText = (item: LookupType) => {
    return item?.text_en
  }

  const options = step.tableDef?.getLookupTable(field.field)

  const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

  const className = getClassName() + ' form-select drop-down'

  return (
    <div className={isFieldDisabled() ? 'flex-grow-1 disabled' : 'flex-grow-1'}>
      <select
        className={className}
        name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
        onChange={handleSelect}
        disabled={isFieldDisabled()}
        value={fieldValue}
      >
        <option value=''>{getPlacholder() ? getPlacholder() : 'Select'}</option>
        {options?.map((item: LookupType) => (
          <option key={item.code} value={item.code}>
            {htmlToReactParser.parse(getLookupItemText(item))}
          </option>
        ))}
      </select>
      {renderValidationError()}
    </div>
  )
}

export default Dropdown
