import React, { useState } from 'react'
import { Form, InputGroup, Modal } from 'reactstrap'

import { DataTools, SyncableQueryType } from '@digitalworkflow/dwtranslateclient'

/**
 * Props for [[`QueryReplyModal`]] component
 */
interface ModalProps {
  isShowQueryReplyModal: boolean | undefined
  handleQueryReplySave: (reply: string) => void
  toggleQueryReplyModal: () => void
  queryRecord: SyncableQueryType | undefined
  is_edit_mode: boolean
  is_query_mode: boolean
}

/**
 * QueryReplyModal component
 *
 * @component QueryReplyModal
 * @category FormElements
 */
const QueryReplyModal = ({
  isShowQueryReplyModal,
  handleQueryReplySave,
  toggleQueryReplyModal,
  queryRecord,
  is_query_mode
}: ModalProps) => {
  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState('')

  const handleCloseEditModal = () => {
    setMessage('')
    setError('')
    toggleQueryReplyModal()
  }

  const handleSave = () => {
    if (message === '') {
      setError('This field is required and may not be left empty.')
    } else {
      handleQueryReplySave(message.trim())
      setMessage('')
      setError('')
    }
  }

  return (
    <Modal size='lg' isOpen={isShowQueryReplyModal}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Reply to Question</h5>
        <button type='button' onClick={handleCloseEditModal} className='close' data-dismiss='modal' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        {queryRecord && (
          <div id='query-form'>
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
              }}
            >
              <div className='col-md-12'>
                <label className='col-md-12 col-form-label'>
                  {`Question asked by ${queryRecord.user_id} on`}{' '}
                  {DataTools.getMoment(queryRecord.query_dt.value)?.format('MMMM Do YYYY, h:mm:ss a')}:
                </label>
                <div className='col-md-12 txt-primary mb-3'>{queryRecord.message}</div>
                {is_query_mode && (
                  <>
                    <label className='col-md-12 col-form-label'>
                      {`Query reply from ${queryRecord.user_id} on`}{' '}
                      {DataTools.getMoment(queryRecord.query_dt.value)?.format('MMMM Do YYYY, h:mm:ss a')}:
                    </label>
                    <div className='col-md-12'>{queryRecord.message}</div>
                  </>
                )}
                <label className='col-md-12 col-form-label'>Type your reply:</label>
                <div className='col-md-12'>
                  <InputGroup>
                    <input
                      className='form-control'
                      name='message'
                      value={message}
                      data-auto-focus
                      type='text'
                      onChange={(e: any): void => {
                        setMessage(e.target.value)
                      }}
                    />
                  </InputGroup>
                </div>
                <div className='col-md-12'>{error && <span className='invalid-feedback'>{error}</span>}</div>
              </div>
            </Form>
          </div>
        )}
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-primary waves-effect waves-light' onClick={handleSave}>
          Submit
        </button>
      </div>
    </Modal>
  )
}

export default QueryReplyModal
