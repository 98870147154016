import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'reactstrap'
import './index.scss'
import { PageToPDF } from '@digitalworkflow/dwtranslateclient'

export type IWorkflowShareProps = {
  pdf_path?: string
}

const WorkflowShare = (props: IWorkflowShareProps) => {
  const [menuShowing, setMenuShowing] = React.useState<boolean>(false)
  const [isRunning, setIsRunning] = React.useState<boolean>(false)
  const [menuPosition, setMenuPosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 })

  /** Display the popup menu upon clicking the share button */
  const handleShareMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setMenuShowing(!menuShowing)
    const maxX = window.innerWidth - 300 // Adjust the value as needed
    const maxY = window.innerHeight - 300 // Adjust the value as needed
    const x = Math.min(e.clientX, maxX)
    const y = Math.min(e.clientY, maxY)
    setMenuPosition({ x, y })
  }

  /** Download / Generate the PDF File */
  const handleClickDownloadPDF = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (isRunning) return

    setIsRunning(true)
    setMenuShowing(false)

    const makeIt = async () => {
      if (props.pdf_path) {
        const doc = new PageToPDF(props.pdf_path)
        const blob = await doc.processPage('XX')
        setIsRunning(false)
        const link = document.createElement('a')
        const safeName = props.pdf_path
          .replace('_task', '')
          .replace(/\//g, '')
          .replace('save_pdf', '')
          .replace(/_/g, '')
        link.download = `DW-${safeName}.pdf`
        link.href = blob
        link.click()
      }
    }

    makeIt()
  }

  return (
    <>
      <div
        className={'workflow-container-share ' + (isRunning ? 'generating' : '')}
        onClick={(e) => handleShareMenu(e)}
      >
        <div className='workflow-state'>
          <div className='workflow-state-step'>
            {isRunning ? (
              <>
                <Spinner color='primary' />
                Generating PDF
              </>
            ) : (
              <>
                <p className='workflow-text'>Options</p>
                <span className='workflow-icon'>
                  <FontAwesomeIcon icon={['far', 'share-nodes']} />
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={'dropdown-background ' + (menuShowing ? 'dropdown-showing' : 'dropdown-hidden')}
        onClick={() => setMenuShowing(false)}
      ></div>

      <div
        className={'dropdown-menu dropdown-menu-sm ' + (menuShowing ? 'dropdown-showing' : 'dropdown-hidden')}
        style={{ top: menuPosition.y, left: menuPosition.x }}
        id='context-menu'
      >
        {props.pdf_path && props.pdf_path !== '' && (
          <a className='dropdown-item' onClick={handleClickDownloadPDF}>
            <FontAwesomeIcon icon={['fal', 'file-pdf']} />
            <span style={{ marginLeft: '10px' }}>Download PDF</span>
          </a>
        )}
      </div>
    </>
  )
}

export default WorkflowShare
