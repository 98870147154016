import React from 'react'
import { ActionBarHelperRecord } from './ActionBarHelper'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StyleHelper } from '../PageEngine/PageButton/StyleHelper'
import { DataTools, PageEngine, PageManager, TaskCollection } from '@digitalworkflow/dwtranslateclient'
import { TaskStatusCode } from '@digitalworkflow/dwtranslateclient/lib/Models/Task/TaskSchema'

type ActionBarButtonProps = {
  button: ActionBarHelperRecord
  page: PageEngine | undefined
  handleCancelRequest: () => void
}

export const ActionBarButton = (props: ActionBarButtonProps) => {
  const navigate = useNavigate()

  /** If the button is Save for Later */
  const isHoldButton = () => props.button.opt.option_1.endsWith('HOLD')

  /** Special button Cancel to stop entring the task */
  const isCancelButton = () => props.button.opt.option_1.endsWith('CANCEL')

  const placeOnHold = async () => {
    /** System action, the task wants to be put on hold */
    if (props.page) {
      const currentRoute = DataTools.internalValidateString(props.page.routePath)
      const existingTask = props.page.logic.getVariable('task_id')
      if (existingTask && existingTask.value && existingTask.value.length > 0) {
        const task = await TaskCollection.findTaskById(existingTask.value)
        if (task) {
          const link = PageManager.linkBuilder(currentRoute, { task_id: existingTask.value })
          console.log('Placing task on hold:', task)
          task.setStatus(TaskStatusCode.TaskOnHold, 'Placed on hold')
          task.doc._unholdRoute = link
          task.save()
          setTimeout(() => {
            navigate(link)
          }, 200)
        }
      }
    }
  }

  /** Called when the button has been clicked */
  const handleButtonClick = (button: ActionBarHelperRecord) => {
    // console.log('handleButtonClick', button)

    if (isHoldButton()) {
      console.log('HoldTask Running:')
      placeOnHold()
    } else if (isCancelButton()) {
      props.handleCancelRequest()
    } else if (button.opt.hasOptions('GoToWebsite')) {
      console.log('GoToWebsite from ActionButton not implemented')
    } else {
      // Internal Navigation to the correct page
      navigate(button.opt.option_1)
    }
  }

  /** Draw a single action button using the style helper to build
  the colors, margins, and padding. */

  if (!props.button.is_visible) return <div>Invisible Button</div>

  let baseStyle = 'btn w-xs waves-effect me-3 floatButton btn-primary'
  baseStyle += ' ' + new StyleHelper(props.button.opt.style).getClassNames()
  baseStyle += ' ' + new StyleHelper(props.button.opt.options).getClassNames()

  if (isHoldButton()) baseStyle += 'btn-info'
  if (isCancelButton()) baseStyle += 'btn-danger'

  return (
    <button type='button' className={baseStyle} onClick={() => handleButtonClick(props.button)}>
      {isHoldButton() && <FontAwesomeIcon icon={['fal', 'alarm-snooze']} className='me-2' />}
      {isCancelButton() && <FontAwesomeIcon icon={['fal', 'trash-can']} className='me-2' />}
      {props.button.opt.text}
    </button>
  )
}
