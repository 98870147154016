import React, { ReactElement } from 'react'

import { FindTaskResults } from '@digitalworkflow/dwtranslateclient'

import './TaskList.scss'
import TaskListButton from './TaskListButton'
import { getTaskListSettings } from './TaskListSettings'

export interface TaskListCardsProps {
  items: FindTaskResults[]
  setSubShowingTask?: (task: FindTaskResults) => void
  renderTaskBadges: (task: FindTaskResults) => ReactElement
  categories: (string | undefined)[]
  setContextTask: (item: FindTaskResults) => void
  setContextMenuPosition: (a: { x: number; y: number }) => void
}

const TaskListCards = ({
  items,
  setSubShowingTask,
  renderTaskBadges,
  categories,
  setContextMenuPosition,
  setContextTask
}: TaskListCardsProps) => {
  const generateButtonList = (categoryName: string) => {
    let tasks = items
      .filter((task) => task.dash_category === categoryName)
      .filter((task) => task.create_dt.value !== undefined)
      .filter((task) => typeof task.create_dt.value !== 'string')

    tasks = tasks.sort((a, b) => new Date(b.create_dt.value).getTime() - new Date(a.create_dt.value).getTime())

    return (
      <div key={'tl' + categoryName} className={getTaskListSettings(categoryName).style}>
        <div className='task_category'>
          {categoryName}
          <span className='badge badge-pill'>{tasks.length}</span>
        </div>
        <div className='task_button_list'>
          {tasks.map((task, index) => {
            return (
              <TaskListButton
                key={'task' + index}
                item={task}
                setContextTask={setContextTask}
                setContextMenuPosition={setContextMenuPosition}
                renderTaskBadges={renderTaskBadges}
                setSubShowingTask={setSubShowingTask}
              />
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className='task-list-cards'>
      {categories.map((category) => {
        return generateButtonList(category || '')
      })}
    </div>
  )
}

export default TaskListCards
