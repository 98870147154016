import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { DataTools, StepGroup } from '@digitalworkflow/dwtranslateclient'

import './pagegroup.scss'

interface Props {
  /** Components to be rendered inside card body.  */
  components: JSX.Element[]
  /** Step data. */
  step: StepGroup
}

export const isJson = (str: string): boolean => {
  if (!isNaN(parseFloat(str))) {
    return false
  }
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * PageGroup component
 *
 * @component PageGroup
 * @category PageElements
 */
const PageGroup = ({ step, components }: Props) => {
  /**
   * Renders Card
   * @returns {Promise<JSX.Element>}
   */
  const renderCard = (cardIconClassName: string, cardType: string) => {
    return (
      <Row>
        <Col md={12}>
          <Card outline color={cardType} className='border'>
            <CardHeader className='bg-transparent'>
              <h5 className={`my-0 text-${cardType}`}>
                {cardIconClassName && cardIconClassName !== '' ? (
                  <i className={`fa-solid ${cardIconClassName} mr-3`}></i>
                ) : (
                  ''
                )}
                {isJson(step.text) ? JSON.parse(step.text)?.name : step.text}
              </h5>
            </CardHeader>
            <CardBody data-testid={`${cardType}-card`}>
              {components}
              {/* <CardTitle className="mt-0"></CardTitle>
              <CardText></CardText> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  const renderButtonMenu = () => {
    return (
      <>
        <div className='buttonMenu'>{components}</div>
      </>
    )
  }

  const icon = DataTools.internalValidateString(step.option_2)

  if (step.options.checkOption('SuccessCard')) {
    return renderCard(icon, 'success')
  }

  if (step.options.checkOption('PrimaryCard')) {
    return renderCard(icon, 'primary')
  }

  if (step.options.checkOption('InfoCard')) {
    return renderCard(icon, 'info')
  }

  if (step.options.checkOption('WarningCard')) {
    return renderCard(icon, 'warning')
  }

  if (step.options.checkOption('ErrorCard') || step.options.checkOption('DangerCard')) {
    return renderCard('fa-bolt', 'danger')
  }

  if (step.options.checkOption('SecondaryCard')) {
    return renderCard('fa-cloud', 'secondary')
  }

  if (step.options.checkOption('DarkCard')) {
    return renderCard('fa-circle-xmark', 'dark')
  }

  if (step.options.checkOption('LightCard')) {
    return renderCard('fa-truck-fast', 'light')
  }

  if (step.options.checkOption('BasicCard')) {
    return renderCard('fa-heart', 'basic')
  }

  if (step.options.checkOption('ButtonMenu')) {
    return renderButtonMenu()
  }

  return (
    <div className={`group ${step.raw.step_option}`} data-testid='page-group'>
      {components}
    </div>
  )
}

export default PageGroup
