import React, { useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { BuildingSearch } from '@digitalworkflow/dwreactcommon'
import Configurations from './Configurations'

const BuildingSearchPage = () => {
  const [showFilterByPostalCode, setShowFilterByPostalCode] = useState<boolean>(true)
  const [showFilterByFMZ, setshowFilterByFMZ] = useState<boolean>(false)
  const [showFilterByRegion, setshowFilterByRegion] = useState<boolean>(false)
  const [showNearest, setShowNearest] = useState<boolean>(false)
  const [showDistance, setShowDistance] = useState<boolean>(true)
  const [clientIds, setClientIds] = useState<string>('')
  const [otherFilters, setOtherFilters] = useState<string>('')
  const [showFav, setShowFav] = useState<boolean>(false)
  const [minSelect, setMinSelect] = useState<number>(1)
  const [maxSelect, setMaxSelect] = useState<number>(10000)
  const [allowPaste, setAllowPaste] = useState<boolean>(true)
  const [templateLine1, setTemplateLine1] = useState<string>('%ID% | %BUILDING_NAME%')
  const [customFilter, setCustomFilter] = useState('LOCATION_CLASS!=LAND')
  const [templateLine2, setTemplateLine2] = useState<string>('%ADDRESS% | %FMZ_DESCRIPTION%')
  const [values, setValues] = useState<(string | number)[]>([])

  const [showComponent, setShowComponent] = useState<boolean>(true)

  const onReloadComponent = () => {
    setShowComponent(false)
    setTimeout(() => {
      setShowComponent(true)
    }, 1000)
  }

  const onConfigChange = (type: string, val: any = null) => {
    switch (type) {
      case 'showFilterByPostalCode':
        setShowFilterByPostalCode(!showFilterByPostalCode)
        break
      case 'showFilterByFMZ':
        setshowFilterByFMZ(!showFilterByFMZ)
        break
      case 'showFilterByRegion':
        setshowFilterByRegion(!showFilterByRegion)
        break
      case 'showNearest':
        setShowNearest(!showNearest)
        break
      case 'showDistance':
        setShowDistance(!showDistance)
        break
      case 'clientIds':
        setClientIds(val)
        break
      case 'otherFilters':
        setOtherFilters(val)
        break
      case 'showFav':
        setShowFav(!showFav)
        break
      case 'minSelect':
        setMinSelect(val)
        break
      case 'maxSelect':
        setMaxSelect(val)
        break
      case 'allowPaste':
        setAllowPaste(!allowPaste)
        break
      case 'templateLine1':
        setTemplateLine1(val)
        break
      case 'templateLine2':
        setTemplateLine2(val)
        break
      case 'customFilter':
        setCustomFilter(val)
        break
      default:
        break
    }
  }
  const onConfigApply = () => {}
  const onConfigCancel = () => {}

  const handleChange = (items: (string | number)[]) => {
    console.log('Building Selected: ', items)
    setValues(items)
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Building Search Control</h4>
            </CardTitle>
            {showComponent && (
              <BuildingSearch
                showFilterPostcode={showFilterByPostalCode}
                showFMZID={showFilterByFMZ}
                showRegion={showFilterByRegion}
                otherFilters={otherFilters}
                showFav={showFav}
                showNearest={showNearest}
                minSelect={minSelect}
                maxSelect={maxSelect}
                allowPaste={allowPaste}
                showDistance={showDistance}
                templateLine1={templateLine1}
                templateLine2={templateLine2}
                clientIds={clientIds}
                onChange={handleChange}
                regexpSel='/.*/'
                customFilter={customFilter}
                value={values}
                pageSize={100}
              />
            )}
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Search Tool Configurations</h4>
            </CardTitle>
            <Configurations
              showFilterByPostalCode={showFilterByPostalCode}
              showFilterByFMZ={showFilterByFMZ}
              showFilterByRegion={showFilterByRegion}
              showNearest={showNearest}
              showFav={showFav}
              showDistance={showDistance}
              minSelect={minSelect}
              maxSelect={maxSelect}
              allowPaste={allowPaste}
              clientIds={clientIds}
              templateLine1={templateLine1}
              templateLine2={templateLine2}
              onChange={onConfigChange}
              onReloadComponent={onReloadComponent}
              onApply={onConfigApply}
              onCancel={onConfigCancel}
              customFilter={customFilter}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BuildingSearchPage
