import React, { useEffect, useState } from 'react'
import SyncModal from '../SyncModal'
import { clearDatabase, getCollectionsCount, syncDatabase, TableRowCount } from '../../utils/syncDatabase'
import { Button, Table, Col, Row } from 'reactstrap'
import './ForceUpdate.scss'

const ForceUpdate = () => {
  const [syncing, setSyncing] = useState(true)
  const [width, setWidth] = useState(0)
  const [message, setMessage] = useState('Database and Configuration Update In Progress')
  const [afterdb, setAfterDb] = useState<TableRowCount>({})
  const [beforedb, setBeforeDb] = useState<TableRowCount>({})
  const [showDiagnostics, setShowDiagnostics] = useState<boolean>(false)

  useEffect(() => {
    const refresh = async () => {
      const result_before: TableRowCount = await getCollectionsCount()
      setBeforeDb(result_before)
      await clearDatabase()
      await syncDatabase(setWidth, setSyncing)
    }
    refresh()
  }, [])

  useEffect(() => {
    if (!syncing) {
      setMessage('Database and Configuration Updated Successfully')
      const fetchData = async () => {
        const result: TableRowCount = await getCollectionsCount()
        setAfterDb(result)
      }
      fetchData()
    }
  }, [syncing])

  const onClickShowDiagnostics = () => {
    setShowDiagnostics(!showDiagnostics)
  }

  return (
    <Row>
      <h6 className='ms-2'>{message}</h6>
      <div>
        <Button color='primary' className='mx-1 px-4' onClick={onClickShowDiagnostics}>
          {showDiagnostics ? 'Hide' : 'Show'} Diagnostics
        </Button>
      </div>
      {showDiagnostics ? (
        <Col className='col-md-8 m-3'>
          <Table size='sm' responsive className='border table-bordered border-secondary'>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Before</th>
                <th>After</th>
                <th>Difference</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(afterdb).map((k, idx) => {
                return (
                  <tr key={idx}>
                    <th scope='row'>{idx++}</th>
                    <td>{k}</td>
                    <td>{beforedb[k]}</td>
                    <td>{afterdb[k]}</td>
                    <td>{(afterdb[k] || 0) - beforedb[k]}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      ) : (
        <></>
      )}

      <SyncModal width={width} isOpen={syncing} />
    </Row>
  )
}
export default ForceUpdate
