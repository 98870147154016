import React, { useEffect, useState, useRef } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { StepTypes, StepTabs } from '@digitalworkflow/dwtranslateclient'
import style from './index.module.scss'
import { GlobalHashHelper } from '../../../utils/HashHelper'
import PageTab from './PageTab'

/**
 * Props for [[`PageTabs`]] component
 */
export interface PageTabsProps {
  /** Step data. */
  step: StepTypes.Tabs
  /** Tabs info */
  tabs: StepTabs.TabInfo[]
}

/**
 * PageTabs component
 *
 * @component PageTabs
 * @category PageElements
 */
const PageTabs = ({ tabs }: PageTabsProps) => {
  const [customActiveTab, setcustomActiveTab] = useState(-1)
  const [stateYellowBadge, setStateYellowBadge] = useState<number[]>([])
  const [stateRedBadge, setStateRedBadge] = useState<number[]>([])
  const [warningMessage, setWarningMessage] = useState<string>('')

  /** These are the two badges that can appear on the top of the tab bar.
  The useRef code here was used because the useState version was missing events that happened
  between react redraws.
  */
  const yellowBadge = useRef<number[]>([])
  const redBadge = useRef<number[]>([])

  const toggleCustom = (tab: number) => {
    if (customActiveTab !== tab) {
      GlobalHashHelper.setOption('tab', tab)
      setcustomActiveTab(tab)
    }
  }

  /**
  upon startup, check first for tab to be defined in the hash as tab=3
  and if not found, then, see if the selected attribute is with one tab
  */
  useEffect(() => {
    while (yellowBadge.current.length < tabs.length) yellowBadge.current.push(0)
    while (redBadge.current.length < tabs.length) redBadge.current.push(0)

    // Default selection on the tab UI
    const hashSetting = GlobalHashHelper.getOption('tab')
    if (hashSetting) {
      setcustomActiveTab(parseInt(hashSetting, 10))
      return
    }

    tabs.map((tab: StepTabs.TabInfo, i: number) => {
      if (tab.type.checkOption('Selected')) {
        setcustomActiveTab(i)
      }
    })
  }, [])

  /** Called by the child PageTab which is called by the render engine.   Each tab sends updates as it loads */
  const handleBadgeChange = (ndx: number, badgeType: string, badgeCount: number) => {
    if (badgeType === 'NeedsAnswer') {
      redBadge.current[ndx] = badgeCount
      // setStateRedBadge([...redBadge.current])
      setStateRedBadge(redBadge.current)

      if (redBadge.current.filter((e) => e > 0).length > 0) {
        setWarningMessage('There are unanswered questions')
      } else {
        setWarningMessage('')
      }
      return
    }

    if (badgeType === 'NeedsResolve') {
      yellowBadge.current[ndx] = badgeCount
      setStateYellowBadge([...yellowBadge.current])
    }
  }

  return (
    <>
      <Nav tabs className={`nav-tabs-custom nav-justified ${style.tabList}`}>
        {tabs.map((tab, index) => (
          <NavItem key={index} className={`${style.tabContainer} tabContainer`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              name={'tab-' + tab.text.replace(/ /g, '-')}
              className={`${customActiveTab === index ? `active ${style.active}` : ''} ${style.tab} tab`}
              onClick={() => {
                toggleCustom(index)
              }}
            >
              <span className='d-none d-sm-block'>
                {tab.text}
                {stateRedBadge[index] === 0 && stateYellowBadge[index] > 0 && (
                  <span className='badge badge-pill badge-warning bg-warning m-1'>{stateYellowBadge[index]}</span>
                )}
                {stateRedBadge[index] > 0 && (
                  <span
                    className='badge badge-pill badge-danger bg-danger m-1'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='There are questions that need to be answered'
                  >
                    {stateRedBadge[index]}
                  </span>
                )}
              </span>
            </NavLink>
          </NavItem>
        ))}
        {warningMessage && (
          <NavItem className={`${style.tabContainer} tabContainer ` + style.tabWarning}>{warningMessage}</NavItem>
        )}
      </Nav>

      <TabContent activeTab={customActiveTab} className={`text-muted mb-3 ${style.tabBody}`}>
        {tabs.map((tab, index) => (
          <TabPane key={index} tabId={index}>
            <PageTab
              key={'pane' + index}
              tabIndex={index}
              tab={tab}
              onBadgeChange={(a: string, b: number) => handleBadgeChange(index, a, b)}
            />
          </TabPane>
        ))}
      </TabContent>
    </>
  )
}

export default PageTabs
