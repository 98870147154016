import React from 'react'

import { FormFieldInputProps, useForm } from '../useForm'

import '../LookupInput/index.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * SwitchInput component
 *
 * @component SwitchInput
 * @category FormElements
 */
const SwitchInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { setValue, isEditFieldOnModal, isFieldDisabled, renderValidationError } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  /**
   * Used to change the value of a radio field.
   *
   * @param e - Used to set the value.
   * @returns Void
   */
  const handleChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, e.target.checked ? '1' : '0')
    } else {
      setValue(e.target.checked ? '1' : '0')
    }
  }

  if (step.tableDef) {
    /** Represents a text formatted version of the field value */
    const myValueText = step.getValueFormatted(field.field)

    const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myValueText

    return (
      <div className={isFieldDisabled() ? 'disabled' : ''}>
        <div className='form-check form-switch mb-1'>
          <input
            type='checkbox'
            id={field.field}
            className='form-check-input'
            name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
            checked={fieldValue === '1'}
            disabled={isFieldDisabled()}
            onChange={handleChangeSwitch}
          />
          <label
            className={`${isFieldDisabled() ? 'form-check-label disabled' : 'form-check-label'}`}
            htmlFor={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
          >
            {htmlToReactParser.parse(field.text)}
          </label>
        </div>
        {renderValidationError()}
      </div>
    )
  }

  return <></>
}

export default SwitchInput
