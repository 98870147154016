import React, { useState, useEffect } from 'react'
import { WorkflowUser } from '@digitalworkflow/dwloginclient'

import './TestAccounts.scss'
import { Task, TaskCollection } from '@digitalworkflow/dwtranslateclient'

const CurrentUserProblemFinder = () => {
  const [user, setCurrectUser] = useState<WorkflowUser | undefined>(undefined)
  const [myAssignedTasks, setMyAssignedTasks] = useState<Task[]>([])

  useEffect(() => {
    const refresh = async () => {
      console.log('Refreshing test accounts.')
      const user = WorkflowUser.GetCurrentUser()
      setCurrectUser(user)

      const allTask =
        (await TaskCollection.getCollection().findAll({ project_id: { $exists: true }, auser: user?.username })) || []
      const myList = allTask.map((task) => new Task(TaskCollection.getCollection(), task))
      setMyAssignedTasks(myList)
    }

    refresh()
  }, [])

  const generateProblems = () => {
    return (
      <div>
        Total Assigned Tasks: {myAssignedTasks.length}
        {/* {myAssignedTasks.map((task) => {
          return (
            <>
              <div> {task._data.project_id} </div>
              <div> {task._data.id} </div>
              <div> {task._data.title} </div>
            </>
          )
        })} */}
      </div>
    )
  }

  const generateCurrentUser = () => {
    return (
      <div className='currentUserTableWrapper'>
        {user ? (
          <table className='table currentUserTable'>
            <thead></thead>
            <tbody>
              <tr>
                <td>Current Username:</td>
                <td> {user.username} </td>
              </tr>
              <tr>
                <td>Current Workgroups:</td>
                <td>{user.getWorkgroups().join(', ')}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
        {generateProblems()}
      </div>
    )
  }

  return <div>{generateCurrentUser()}</div>
}

export default CurrentUserProblemFinder
