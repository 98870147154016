import React, { useEffect, useState } from 'react'

import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../utils/LocalSettings'
import { getLoginPortalID } from '../constants'

const token = LocalSettings.getPortalAuthToken()
const userStr = LocalSettings.getPortalUser()

const authService = AuthService.instance()

let userObj: any = null
try {
  userObj = userStr ? JSON.parse(userStr) : null
} catch (e) {}

export interface IAuth {
  username: string
  email: string
  roles?: Array<any>
  firstName?: string
  lastName?: string
  portals_enabled?: Array<any>
}

type IAuthContext = {
  isAuthenticated: boolean
  setIsAuthenticated: (val: boolean) => void
  user: IAuth
  setUser: (val: IAuth) => void
  logout: () => void
  defaultRoute: string
  setDefaultRoute: (val: string) => void
}

export const AuthContext: React.Context<IAuthContext> = React.createContext({} as IAuthContext)

export const AuthProvider = ({ children }: { children: any }): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!token)
  const [user, setUser] = useState<IAuth>({
    username: '',
    email: '',
    roles: [],
    portals_enabled: [],
    firstName: '',
    lastName: ''
  })
  const [defaultRoute, setDefaultRoute] = useState('')

  const logout = () => {
    /** Set redirect Url before log out */
    // localStorage.setItem('dwportal_redirect_url', document.location.pathname)
    LocalSettings.removePortalAuthToken()
    LocalSettings.removePortalUser()

    setIsAuthenticated(false)
  }

  useEffect(() => {
    const initAuth = async () => {
      if (token) {
        AuthService.setPortalLoginId(getLoginPortalID())

        const res: AuthResultType = await authService.authUserFromToken(token)
        console.log(res)
        if (res) {
          if (res?.is_error === false) {
            setUser({
              username: res?.data?.user.email,
              email: res?.data?.user.email,
              portals_enabled: res?.data?.user.portals_enabled
            })
            return
          }
        }
      }
      setIsAuthenticated(false)
      LocalSettings.removePortalAuthToken()
      LocalSettings.removePortalUser()
    }

    if (token) {
      // call authuserFromToken to validate auth
      initAuth()
    }
  }, [])

  useEffect(() => {
    if (userObj && userObj?.email) {
      console.log('init userObj', userObj)
      setUser({
        username: userObj?.email,
        email: userObj?.email,
        portals_enabled: userObj?.portals_enabled
      })
    }
  }, [userObj])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        logout,
        defaultRoute,
        setDefaultRoute
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): IAuthContext => React.useContext(AuthContext)
